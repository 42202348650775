import React, { useState, useEffect, memo } from 'react';
import '../styles/signalAutomation.css';
import { getOnepgrContactLists, getSignalAutomationList, getContactListEmails } from '../api/onepgrApi';

const SignalAutomationPopup = memo(({ onClose, onSubmit }) => {
    const [requestName, setRequestName] = useState('');
    const [researchNotes, setResearchNotes] = useState('');
    const [prospectEmails, setProspectEmails] = useState('');
    const [selectedSignalList, setSelectedSignalList] = useState('');
    const [selectedContactList, setSelectedContactList] = useState('');
    const [selectedContactListName, setSelectedContactListName] = useState('');
    const [prospectOption, setProspectOption] = useState('notes');
    const [signalLists, setSignalLists] = useState([]);
    const [contactLists, setContactLists] = useState([]);
    const [contactEmails, setContactEmails] = useState([]);
    const [manualEmail, setManualEmail] = useState('');

    const storedUser = localStorage.getItem('user');
    const user = JSON.parse(storedUser);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const signalData = await getSignalAutomationList(user.userId);
                if (signalData && signalData.data) {
                    setSignalLists(signalData.data);
                } else {
                    console.error('No signal lists found in the response');
                }

                const contactData = await getOnepgrContactLists();
                if (contactData && contactData.response && contactData.response.lists_listing) {
                    setContactLists(contactData.response.lists_listing);
                } else {
                    console.error('No contact lists found in the response');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleContactListChange = async (e) => {
        const listId = e.target.value;
        const listName = e.target.options[e.target.selectedIndex].text;
        setSelectedContactList(listId);
        setSelectedContactListName(listName);

        if (listId) {
            try {
                const emailsData = await getContactListEmails(listId);
                console.log('emailsData----->', emailsData);

                // Extract emails from the response
                if (emailsData && emailsData.response && emailsData.response.contacts_list) {
                    const emails = emailsData.response.contacts_list
                        .map(contact => contact.email) // Extract the primary email
                        .filter(email => email && email !== "null"); // Filter out null or invalid emails

                    // Set the extracted emails to the state
                    setContactEmails(emails);
                } else {
                    console.error('No contacts found in the response');
                    setContactEmails([]); // Reset emails if no data is found
                }
            } catch (error) {
                console.error('Error fetching emails:', error);
                setContactEmails([]); // Reset emails on error
            }
        } else {
            setContactEmails([]); // Reset emails if no list is selected
        }
    };
    const handleAddManualEmail = () => {
        if (manualEmail && !contactEmails.includes(manualEmail)) {
            setContactEmails([...contactEmails, manualEmail]);
            setManualEmail('');
        }
    };

    const handleSubmit = () => {
        const formData = {
            requestName,
            researchNotes: prospectOption === 'notes' ? researchNotes : '',
            prospectEmails: prospectOption === 'emails' ? prospectEmails : '',
            selectedSignalList,
            selectedContactList,
            selectedContactListName,
            contactEmails,
        };
        onSubmit(formData);
        onClose();
    };
    const handleRemoveEmail = (index) => {
        // Create a new array without the email at the specified index
        const updatedEmails = contactEmails.filter((_, i) => i !== index);
        setContactEmails(updatedEmails); // Update the state
    };

    return (
        <div className="signal-automation-popup-overlay">
            <div className="signal-automation-popup-content">
                <button className="signal-automation-close-button" onClick={onClose}>x</button>
                <h2 className="signal-automation-title">Signal Automation Request</h2>
                <div className="signal-automation-form">
                    <div className="signal-automation-form-container">
                        <div className="signal-automation-left-column">
                            <div className="signal-automation-form-group">
                                <label><strong>Request Name:</strong></label>
                                <input
                                    type="text"
                                    placeholder="Request Name"
                                    value={requestName}
                                    onChange={(e) => setRequestName(e.target.value)}
                                    className="signal-automation-input"
                                />
                            </div>
                            <div className="signal-automation-form-group">
                                <label><strong>Select Signal List:</strong></label>
                                <select
                                    value={selectedSignalList}
                                    onChange={(e) => setSelectedSignalList(e.target.value)}
                                    className="signal-automation-select"
                                >
                                    <option value="">--- Select ---</option>
                                    {signalLists.map((signal) => (
                                        <option key={signal.list_id} value={signal.list_id}>
                                            {signal.list_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="signal-automation-form-group">
                                <label><strong>Select Contact List:</strong></label>
                                <select
                                    value={selectedContactList}
                                    onChange={handleContactListChange}
                                    className="signal-automation-select"
                                >
                                    <option value="">--- Select ---</option>
                                    {contactLists.map((contact) => (
                                        <option key={contact.list_id} value={contact.list_id}>
                                            {contact.list_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="signal-automation-right-column">
                            <div className="signal-automation-form-group-radio">
                                <label><strong>Signal Automation Request Options</strong></label>
                                <div className="signal-automation-radio-group">
                                    <label>
                                        <input
                                            type="radio"
                                            value="notes"
                                            checked={prospectOption === 'notes'}
                                            onChange={() => setProspectOption('notes')}
                                        />
                                        Prospect Research Notes
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value="emails"
                                            checked={prospectOption === 'emails'}
                                            onChange={() => setProspectOption('emails')}
                                        />
                                        Prospect Emails
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="signal-automation-emails-container">
                        <div className="signal-automation-emails-count">

                        </div>
                        <div className="signal-automation-emails-list">
                            {contactEmails.map((email, index) => (
                                <div key={index} className="signal-automation-email-item">
                                    {email}
                                    <button
                                        className="signal-automation-remove-button"
                                        onClick={() => handleRemoveEmail(index)} // Remove email on button click
                                    >
                                        ×
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="signal-automation-add-email">
                            <input
                                type="text"
                                placeholder="Add more people.."
                                value={manualEmail}
                                onChange={(e) => setManualEmail(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' && manualEmail.trim()) {
                                        handleAddManualEmail();
                                    }
                                }}
                                className="signal-automation-input"
                            />
                        </div>
                        
                    </div>
                    <p className="signal-automation-emails-count"><strong >{contactEmails.length}</strong>contacts selected</p>
                </div>
                <div className="signal-automation-submit-button-container">
                    <button className="signal-automation-submit-button" onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
            </div>
        </div>

    );
});

export default SignalAutomationPopup;
import React, { useState } from 'react';
import { Briefcase, GraduationCap, Award, MapPin, Link, Users, Building } from 'lucide-react';
import '../styles/linkedinPofileDataTable.css';
import { prospectResearch, companyResearch, langFlowProspectResearch, langFlowCompanyResearch } from '../api/researchApi';
import toasterService from '../services/toasterService';
import { enrichPeopleData } from '../api/api';
import SignalAutomationPopup from './signalAutomation';
import { getContactListEmails, updateContact } from '../api/onepgrApi';


const LinkedInProfileDisplay = ({ data, handleSaveToOnePgr, savedContacts, selectedListId }) => {
  console.log("selectedListId-->",selectedListId)
  const [isLoading, setIsLoading] = useState({
    prospect: false,
    company: false,
  });

  const [researchResults, setResearchResults] = useState({
    prospect: null,
    company: null,
  });

  const [activeTab, setActiveTab] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const [contactDetails, setContactDetails] = useState({
    name: '',
    title: '',
    company: '',
    linkedinUrl: '',
    email: '',
    phone: '',
  });

  const [showPopup, setShowPopup] = useState(false);
  const [showSignalPopup, setShowSignalPopup] = useState(false); // State for Signal Automation Request popup

  const [enrichType, setEnrichType] = useState('email');
  const [isSavingNotes, setIsSavingNotes] = useState(false);

  const handleSaveContactToOnePgr = async () => {
    try {
      setIsSaving(true);
      const formattedContact = {
        first_name: contactDetails.name.split(' ')[0] || '',
        last_name: contactDetails.name.split(' ')[1] || '',
        name: contactDetails.name || '',
        title: contactDetails.title || '',
        headline: data.headline || '',
        city: data.location?.split(', ')[0] || '',
        state: data.location?.split(', ')[1] || '',
        country: data.location?.split(', ')[2] || '',
        email: contactDetails.email || '',
        email_status: contactDetails.email ? "verified" : "unverified",
        email_domain_catchall: false,
        notes: 'nikhil test',
        departments: [],
        functions: [],
        subdepartments: [],
        seniority: null,
        organization: {
          industry: data.positions?.[0]?.companyIndustry || '',
          location: data.location || '',
          name: contactDetails.company || '',
          primary_domain: contactDetails.company ? contactDetails.company.toLowerCase().replace(/\s+/g, '') + '.com' : '',
          size: '',
        },
        organization_id: '',
        linkedin_url: contactDetails.linkedinUrl || '',
        facebook_url: null,
        github_url: null,
        twitter_url: null,
        photo_url: data.profilePicture || null,
        employment_history: data.positions?.map((position) => ({
          company: position.companyName || '',
          title: position.title || '',
          location: position.location || '',
          start_date: position.start?.year ? `${position.start.year}-${position.start.month || '01'}-01` : '',
          end_date: position.end?.year ? `${position.end.year}-${position.end.month || '12'}-31` : 'Present',
        })) || [],
        is_likely_to_engage: true,
        revealed_for_current_team: true,
        show_intent: true,
        intent_strength: null,
        extrapolated_email_confidence: null,
        id2: `id-${Date.now()}-${Math.floor(Math.random() * 10000)}`,
      };

      const response = await handleSaveToOnePgr([formattedContact]);
      console.log("API Response:", response);
    } catch (error) {
      toasterService.error('Failed to save contact to OnePgr');
      console.error('Error saving contact to OnePgr:', error);
    } finally {
      setIsSaving(false);
      setShowPopup(false);
    }
  };

  const handleEnrichContact = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, enrich: true }));
      const personData = {
        firstName: contactDetails.name.split(' ')[0],
        lastName: contactDetails.name.split(' ')[1] || '',
        profileLink: contactDetails.linkedinUrl,
        organization: {
          name: contactDetails.company,
        },
      };

      const response = await enrichPeopleData([personData], enrichType);

      if (response.success && response.data.enriched_people.length > 0) {
        const enrichedPerson = response.data.enriched_people[0];
        const enrichedEmail = enrichedPerson.enriched_email === "Email not found" || !enrichedPerson.enriched_email
          ? "no-email@test.com"
          : enrichedPerson.enriched_email;

        setContactDetails((prev) => ({
          ...prev,
          email: enrichedEmail || prev.email,
          phone: enrichedPerson.enriched_phone || prev.phone,
        }));

        toasterService.success('Contact enriched successfully');
      } else {
        toasterService.error('No data found for enrichment');
      }
    } catch (error) {
      toasterService.error('Failed to enrich contact');
      console.error('Error enriching contact:', error);
    } finally {
      setIsLoading((prev) => ({ ...prev, enrich: false }));
    }
  };

  const openPopup = () => {
    const contact = {
      name: `${data.firstName} ${data.lastName}`,
      title: data.headline,
      company: data.positions?.[0]?.companyName || '',
      linkedinUrl: data.linkedinUrl || '',
      email: '',
      phone: '',
    };

    setContactDetails(contact);
    setShowPopup(true);
  };

  const handleProspectResearch = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, prospect: true }));
      const prospectData = {
        name: data.firstName + ' ' + data.lastName || '',
        company: data.positions?.[0]?.companyName || '',
        title: data.headline || '',
      };
      console.log("Prospect Data:", prospectData);
      const result = await langFlowProspectResearch({
        inputs: {
          input: prospectData,
        },
        tweaks: {
          "ChatOpenAI-6OQof": {},
          "PromptTemplate-BxRo6": {},
          "LLMChain-G3AuZ": {},
          "StructuredOutputParser-29uwH": {},
          "ResponseSchema-ssl3m": {},
        },
      });
      console.log("Prospect Research Result:", result);

      // Parse the JSON string inside result.result.text
      const parsedResult = JSON.parse(result.result.text.replace(/```json\n|\n```/g, ''));

      setResearchResults((prev) => ({
        ...prev,
        prospect: parsedResult.prospect_content, // Use the parsed JSON data
      }));
      setActiveTab('prospect');
      toasterService.success('Prospect research completed successfully');
    } catch (error) {
      toasterService.error('Prospect research failed');
      console.error('Prospect research error:', error);
    } finally {
      setIsLoading((prev) => ({ ...prev, prospect: false }));
    }
  };


  const handleCompanyResearch = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, company: true }));
      const companyData = {
        name: data.positions?.[0]?.companyName || '',
        industry: data.positions?.[0]?.companyIndustry || 'Unknown Industry',
      };
      console.log("Company Data:", companyData);

      const result = await langFlowCompanyResearch({
        inputs: {
          input: companyData,
        },
        tweaks: {
          "ChatOpenAI-6OQof": {},
          "PromptTemplate-BxRo6": {},
          "LLMChain-G3AuZ": {},
          "StructuredOutputParser-29uwH": {},
          "ResponseSchema-ssl3m": {},
        },
      });
      console.log("Company Research Result:", result);

      // Clean the JSON string
      const cleanedJsonString = result.result.text
        .replace(/```json\n|\n```/g, '') // Remove triple backticks
        .replace(/[\u0000-\u001F\u007F-\u009F]/g, ''); // Remove control characters

      console.log("Cleaned JSON String:", cleanedJsonString);

      // Parse the cleaned JSON string
      const parsedResult = JSON.parse(cleanedJsonString);

      console.log("Parsed Company Research Result:", parsedResult);

      // Extract the company_content object
      const companyContent = parsedResult.company_content;

      // Update state with the structured content
      setResearchResults((prev) => ({
        ...prev,
        company: companyContent, // Use the structured content directly
      }));
      setActiveTab('company');
      toasterService.success('Company research completed successfully');
    } catch (error) {
      toasterService.error('Company research failed');
      console.error('Company research error:', error);
    } finally {
      setIsLoading((prev) => ({ ...prev, company: false }));
    }
  };

  const handleSaveResearchToContactNotes = async () => {
    setIsSavingNotes(true);
    try {
      if (!selectedListId) {
        toasterService.warning('Save contact to OnePgr and save research to contact notes');
        return;
      }

      // Get existing contacts from the list
      const existingContactsResponse = await getContactListEmails(selectedListId);
      console.log("existingContactsResponse-->",existingContactsResponse)
      const existingContacts = existingContactsResponse?.response?.contacts_list || [];

      // Check if contact exists by name or email
      const matchingContact = existingContacts.find(contact => 
        (contact.email && contact.email === contactDetails.email) || 
        (contact.name && contact.name === `${data.firstName} ${data.lastName}`)
      );

      if (!matchingContact) {
        toasterService.error('Contact not found in the selected list');
        return;
      }

      // Prepare research notes
      const researchNotes = activeTab === 'prospect' 
        ? Object.entries(researchResults.prospect).map(([key, value]) => `${key}:\n${value}`).join('\n\n')
        : Object.entries(researchResults.company).map(([key, value]) => `${key}:\n${value}`).join('\n\n');

      // Update contact with new notes
      const updatedContact = {
        contact_id: matchingContact.contact_id,
        notes: researchNotes,
        // Keep other contact details unchanged
        name: matchingContact.name,
        email: matchingContact.email,
      };

      // Call update contact API
      await updateContact(updatedContact);
      toasterService.success('Research saved to OnePgr contact notes successfully');
    } catch (error) {
      toasterService.error('Failed to save research to OnePgr contact notes');
      console.error('Error saving research to OnePgr contact notes:', error);
    } finally {
      setIsSavingNotes(false);
    }
  };

  if (!data) {
    return <div>No profile data available</div>;
  }

  const {
    firstName,
    lastName,
    headline,
    summary,
    profilePicture,
    certifications = [],
    educations = [],
    positions = [],
    location,
    formattedSkills = '',
  } = data;

  const skills = formattedSkills.split('\n').map((skill) => {
    try {
      const [name, endorsements] = skill.split(' (');
      const endorsementsCount = parseInt(endorsements?.replace(' endorsements)', '')) || 0;
      return {
        name: name?.trim() || 'Unknown Skill',
        endorsementsCount,
      };
    } catch (error) {
      console.error('Error parsing skill:', skill, error);
      return {
        name: 'Unknown Skill',
        endorsementsCount: 0,
      };
    }
  });

  const handleSubmit = (formData) => {
    console.log('Form Data Submitted:', formData);
    // Handle form submission logic here
    setShowSignalPopup(false); // Close the popup after submission
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="cover-image">
          <div className="profile-image">
            {profilePicture ? (
              <img src={profilePicture} alt={`${firstName} ${lastName}`} />
            ) : (
              <Building size={60} color="#9ca3af" />
            )}
          </div>
        </div>

        <div className="profile-info">
          <div className="name-section">
            <h1>
              {firstName} {lastName}
            </h1>
            <div className="dropdown">
              <button className="custom-button more-options">More Options</button>
              <div className="dropdown-content">
                <button className="dropdown-item">Get Email</button>
                <button className="dropdown-item">Get Phone</button>
                <button className="dropdown-item">Get Email & Phone</button>
              </div>
            </div>
          </div>
          <h2>{headline}</h2>
          <div className="location-info">
            <MapPin />
            <span>{location}</span>
          </div>
          <p>{summary}</p>
        </div>
      </div>

      <div className="section">
        <h3>
          <Briefcase />
          Experience
        </h3>
        {positions.map((position, index) => (
          <div key={index} className="experience-item">
            <div className="company-logo">
              {position.companyLogo ? (
                <img src={position.companyLogo} alt={position.companyName} />
              ) : (
                <Building size={24} color="#9ca3af" />
              )}
            </div>
            <div className="job-info">
              <h4>{position.title}</h4>
              <div className="company">{position.companyName}</div>
              <div className="duration">
                {position.start?.month && position.start?.year
                  ? `${position.start.month}/${position.start.year}`
                  : ''}
                {position.end?.month && position.end?.year
                  ? ` - ${position.end.month}/${position.end.year}`
                  : ' - Present'}
              </div>
              {position.location && <div className="duration">{position.location}</div>}
              <div className="description">{position.description}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="section">
        <h3>
          <GraduationCap />
          Education
        </h3>
        {educations.map((school, index) => (
          <div key={index} className="experience-item">
            <div className="company-logo">
              {school.logoUrl ? (
                <img src={school.logoUrl} alt={school.schoolName} />
              ) : (
                <GraduationCap size={24} color="#9ca3af" />
              )}
            </div>
            <div className="job-info">
              <h4>{school.schoolName}</h4>
              <div className="description">{school.degree}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="section">
        <h3>
          <Award />
          Skills
        </h3>
        <div className="skills-grid">
          {skills
            .sort((a, b) => (b.endorsementsCount || 0) - (a.endorsementsCount || 0))
            .slice(0, 12)
            .map((skill, index) => (
              <div key={index} className="skill-item">
                <span className="skill-name">{skill.name}</span>
                <span className="endorsements">{skill.endorsementsCount || 0} </span>
              </div>
            ))}
        </div>
      </div>

      <div className="buttons-section">
        <button
          className="custom-button prospect-research"
          onClick={handleProspectResearch}
          disabled={isLoading.prospect}
        >
          {isLoading.prospect ? (
            <span className="button-researching">
              <div className="button-spinner"></div>
              Researching<span className="button-loading-dots">.</span>
            </span>
          ) : (
            'Prospect Research'
          )}
        </button>

        <button
          className="custom-button company-research"
          onClick={handleCompanyResearch}
          disabled={isLoading.company}
        >
          {isLoading.company ? (
            <span className="button-researching">
              <div className="button-spinner"></div>
              Researching<span className="button-loading-dots">.</span>
            </span>
          ) : (
            'Company Research'
          )}
        </button>

        <button className="custom-button signal-automation" onClick={() => setShowSignalPopup(true)}>
          Signal Automation Request
        </button>
        <button className="custom-button save-to-onepgr" onClick={openPopup}>
          Save Contact to OnePgr
        </button>
      </div>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button
              className="custom-button close-popup"
              onClick={() => setShowPopup(false)}
            >
              x
            </button>
            <h2 className="contact-details-title">Contact Details</h2>
            <div className="contact-details">
              <div className="form-group">
                <label><strong>Name:</strong></label>
                <input
                  type="text"
                  value={contactDetails.name}
                  readOnly
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label><strong>Title:</strong></label>
                <input
                  type="text"
                  value={contactDetails.title}
                  readOnly
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label><strong>Company:</strong></label>
                <input
                  type="text"
                  value={contactDetails.company}
                  readOnly
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label><strong>LinkedIn URL:</strong></label>
                <input
                  type="text"
                  value={contactDetails.linkedinUrl}
                  readOnly
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label><strong>Email:</strong></label>
                <input
                  type="text"
                  value={contactDetails.email || 'Not available'}
                  readOnly
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label><strong>Phone:</strong></label>
                <input
                  type="text"
                  value={contactDetails.phone || 'Not available'}
                  readOnly
                  className="form-control"
                />
              </div>
            </div>

            <div className="popup-buttons">
              <button
                className="custom-button enrich-contact"
                onClick={handleEnrichContact}
                disabled={isLoading.enrich || (contactDetails.email && contactDetails.phone)}
              >
                {isLoading.enrich ? (
                  <span className="button-researching">
                    <div className="button-spinner"></div>
                    Enriching<span className="button-loading-dots">.</span>
                  </span>
                ) : (
                  'Enrich Contact'
                )}
              </button>

              <button
                className="custom-button save-to-onepgr"
                onClick={handleSaveContactToOnePgr}
                disabled={isSaving}
              >
                {isSaving ? (
                  <span className="button-saving">
                    <div className="button-spinner"></div>
                    Saving<span className="button-loading-dots">...</span>
                  </span>
                ) : (
                  'Save to OnePgr Contacts'
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {showSignalPopup && (
        <SignalAutomationPopup
          onClose={() => setShowSignalPopup(false)}
          onSubmit={handleSubmit}
        />
      )}

      {(researchResults.prospect || researchResults.company) && (
        <div className="research-results-section">
          <div className="tabs">
            {researchResults.prospect && (
              <button
                className={`tab ${activeTab === 'prospect' ? 'active' : ''}`}
                onClick={() => setActiveTab('prospect')}
              >
                Prospect Research
              </button>
            )}
            {researchResults.company && (
              <button
                className={`tab ${activeTab === 'company' ? 'active' : ''}`}
                onClick={() => setActiveTab('company')}
              >
                Company Research
              </button>
            )}
          </div>

          <div className="tab-content">
            {activeTab === 'prospect' && researchResults.prospect && (
              <div className="result-block">
                <div className="research-content">
                  <h3>Professional Background Analysis</h3>
                  <p>{researchResults.prospect["Professional Background Analysis"]}</p>

                  <h3>Key Achievements and Experience</h3>
                  <p>{researchResults.prospect["Key Achievements and Experience"]}</p>

                  <h3>Recent Activities or News</h3>
                  <p>{researchResults.prospect["Recent Activities or News"]}</p>

                  <h3>Potential Talking Points</h3>
                  <p>{researchResults.prospect["Potential Talking Points"]}</p>

                  <h3>Engagement Recommendations</h3>
                  <p>{researchResults.prospect["Engagement Recommendations"]}</p>

                  <h3>Areas of Interest or Expertise</h3>
                  <p>{researchResults.prospect["Areas of Interest or Expertise"]}</p>
                </div>
                <button
  className="custom-button save-to-onepgr-notes"
  onClick={handleSaveResearchToContactNotes}
  disabled={isSavingNotes}
>
  {isSavingNotes ? (
    <span className="button-saving">
      <div className="button-spinner"></div>
      Saving<span className="button-loading-dots">...</span>
    </span>
  ) : (
    'Save to OnePgr Contact Notes'
  )}
</button>
              </div>
            )}

            {activeTab === 'company' && researchResults.company && (
              <div className="result-block">
                <div className="research-content">
                  <h3>Company Overview</h3>
                  <p>{researchResults.company["Company Overview"]}</p>

                  <h3>Financial Insights</h3>
                  <p>{researchResults.company["Financial Insights"]}</p>

                  <h3>Sales Organization Analysis</h3>
                  <p>{researchResults.company["Sales Organization Analysis"]}</p>

                  <h3>Market Position</h3>
                  <p>{researchResults.company["Market Position"]}</p>

                  <h3>Current Business Focus</h3>
                  <p>{researchResults.company["Current Business Focus"]}</p>

                  <h3>Pain Points and Opportunities</h3>
                  <p>{researchResults.company["Pain Points and Opportunities"]}</p>

                  <h3>GTM (Go-To-Market) Strategy</h3>
                  <p>{researchResults.company["GTM (Go-To-Market) Strategy"]}</p>

                  <h3>Recommended Approach Strategy</h3>
                  <p>{researchResults.company["Recommended Approach Strategy"]}</p>
                </div>
               
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LinkedInProfileDisplay;
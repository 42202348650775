import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './accountSettings.css';
import { useCombinedContext } from '../context/userContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const AccountSettings = () => {
  const { user: contextUser } = useCombinedContext();
  const [inputValues, setInputValues] = useState({});
  const [userDetails, setUserDetails] = useState(null);
  const [editedProviders, setEditedProviders] = useState({});
  const [activeTab, setActiveTab] = useState('all');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const user = contextUser || JSON.parse(localStorage.getItem('user')) || {};


  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const apiProviders = [
    { name: 'OpenAI', id: 'open_ai', fields: ['API Key', 'API Secret'], category: 'LLM' },
    { name: 'Skrapp', id: 'skrapp_io', fields: ['API Key', 'API Secret'], category: 'data' },
    { name: 'Seamless.AI', id: 'seamless_ai', fields: ['API Key', 'API Secret'], category: 'data'},
    { name: 'Synthesia', id: 'synthesia', fields: ['API Key', 'API Secret'], category: 'app' },
    { 
      name: 'Apollo.io', 
      id: 'apollo_io', 
      fields: ['Name your key', 'API Key'], 
      category: 'data',
      showInfo: true,
      tooltip: "To get your Apollo.io API key: 1. Log in to Apollo.io 2. Go to https://developer.apollo.io/keys in new tab 3. Create a new API key 4. Copy the key and paste it here"
    },
    { 
      name: 'Leadmagic.io', 
      id: 'leadmagic_io', 
      fields: ['Name your key', 'API Key'], 
      category: 'data',
      showInfo: true,
      tooltip: "To get your Leadmagic.io API key: 1. Log in to Leadmagic.io 2. Navigate to Account Settings > API 3. Copy the generated key and paste it here"
    },
    { 
      name: 'People Data Labs', 
      id: 'pdl', 
      fields: ['Name your key', 'API Key'], 
      category: 'data',
      showInfo: true,
      tooltip: "To get your PDL API key: 1. Log in to People Data Labs 2. Go to your dashboard settings 3. Navigate to API Keys section 4. Copy your API key and paste it here"
    },
  ];

  useEffect(() => {
    const storedUserDetails = localStorage.getItem('userDetails');
    const storedInputValues = localStorage.getItem('inputValues');

    // Use stored data if available (for immediate UI rendering)
    if (storedUserDetails) {
      const parsedUserDetails = JSON.parse(storedUserDetails);
      setUserDetails(parsedUserDetails);

      if (storedInputValues) {
        const parsedInputValues = JSON.parse(storedInputValues);
        setInputValues(parsedInputValues);
      } else if (parsedUserDetails.api_keys) {
        const apiKeys = JSON.parse(parsedUserDetails.api_keys);
        const initialInputValues = {};
        apiProviders.forEach(provider => {
          const providerKeys = apiKeys[provider.id];
          initialInputValues[provider.id] = providerKeys
            ? {
                field1: providerKeys.name || providerKeys.key || '',
                field2: providerKeys.secret || providerKeys.key || ''
              }
            : { field1: '', field2: '' };
        });
        setInputValues(initialInputValues);
      }
    }

    // Always fetch fresh data from the server
    fetchUserDetails();
  }, []);

  const fetchUserDetails = async () => {
    const body = new FormData();
    body.append("id", user.userId);
    try {
      const response = await axios.post(
        'https://onepgr.com/users/get_profile_attributes?onepgr_apicall=1&xhr_flag=1',
        body,
        { withCredentials: true }
      );

      const profileData = JSON.parse(response.data.response);
      setUserDetails(profileData);

      // Store the fetched data in localStorage
      localStorage.setItem('userDetails', JSON.stringify(profileData));

      if (profileData.api_keys) {
        const apiKeys = JSON.parse(profileData.api_keys);
        const initialInputValues = {};
        apiProviders.forEach(provider => {
          const providerKeys = apiKeys[provider.id];
          initialInputValues[provider.id] = providerKeys
            ? {
                field1: providerKeys.name || providerKeys.key || '',
                field2: providerKeys.secret || providerKeys.key || ''
              }
            : { field1: '', field2: '' };
        });
        setInputValues(initialInputValues);

        // Store the input values in localStorage
        localStorage.setItem('inputValues', JSON.stringify(initialInputValues));
      }
    } catch (error) {
      console.error('Error fetching profile attributes:', error);
    }
  };

  

  const handleInputChange = (providerId, field, value) => {
    setInputValues(prev => ({
      ...prev,
      [providerId]: {
        ...prev[providerId],
        [field]: value
      }
    }));
    setEditedProviders(prev => ({
      ...prev,
      [providerId]: true
    }));
  };

  const handleSave = async (providerId) => {
    let apiKeys = userDetails.api_keys ? JSON.parse(userDetails.api_keys) : {};
    apiKeys[providerId] = {
      name: inputValues[providerId]?.field1 || '',
      key: inputValues[providerId]?.field2 || ''
    };

    const payLoadData = {
      api_keys: JSON.stringify(apiKeys)
    };

    try {
      await saveUserApiSecretDetails(payLoadData);
      setEditedProviders(prev => ({
        ...prev,
        [providerId]: false
      }));
      await fetchUserDetails();
    } catch (error) {
      console.error(`Error saving API keys for ${providerId}:`, error);
    }
  };

  const saveUserApiSecretDetails = async (payLoadData) => {
    let body = new FormData();
    body.append("onepgr_apicall", "1");
    body.append("service_name", "onepgr");
    body.append("xhr_flag", "1");
    body.append("api_keys", payLoadData.api_keys);

    try {
      await axios.post("https://onepgr.com/users/set_user_attributes", body, { withCredentials: true });
    } catch (error) {
      throw error;
    }
  };

  const filteredProviders = apiProviders.filter(provider =>
    activeTab === 'all' || provider.category === activeTab
  );


 

  return (
    <div className="api-keys-container">
      <h2 className="api-keys-title">API Keys</h2>

      {/* Tabs Navigation */}
      <div className="tabs-container">
        {['all', 'LLM', 'data', 'app'].map(tab => (
          <button
            key={tab}
            className={`tab-button ${activeTab === tab ? 'active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>

      {/* API Providers */}
      {filteredProviders.map(provider => (
        <div key={provider.id} className="api-provider">
          <p className="provider-name">
            {provider.name}
            {provider.showInfo && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-api-info`} className="custom-tooltip">
                    {provider.tooltip}
                  </Tooltip>
                }
              >
                <span className="info-icon-wrapper">
                  <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
                </span>
              </OverlayTrigger>
            )}
          </p>
          <div className="api-inputs">
            <input
              type="text"
              placeholder={provider.fields[0]}
              className="api-input"
              value={inputValues[provider.id]?.field1 || ''}
              onChange={(e) => handleInputChange(provider.id, 'field1', e.target.value)}
            />
            <input
              type="text"
              placeholder={provider.fields[1]}
              className="api-input"
              value={inputValues[provider.id]?.field2 || ''}
              onChange={(e) => handleInputChange(provider.id, 'field2', e.target.value)}
            />
            <button
              className={`save-button ${editedProviders[provider.id] ? 'active' : ''}`}
              onClick={() => handleSave(provider.id)}
              disabled={!editedProviders[provider.id]}
            >
              Save
            </button>
          </div>
          
        </div>
      ))}
    </div>
  );
};

export default AccountSettings;
import React, { useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import './GeneratedContent.css';

const GeneratedContent = ({ isEditing, editableContent, setEditableContent, generatedContent }) => {
    console.log(generatedContent);
    const textAreaRef = useRef(null);

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    }, [editableContent]);

    const formatContent = (content) => {
        if (!content) return '';
        
        // Remove extra spaces at the beginning of lines
        return content.split('\n')
            .map(line => line.trim())
            .join('\n')
            .replace(/\n{3,}/g, '\n\n'); // Replace multiple newlines with double newlines
    };

    const components = {
        h1: ({ children }) => (
            <h1 className="title">{children}</h1>
        ),
        h2: ({ children }) => (
            <h2 className="section-title">{children}</h2>
        ),
        p: ({ children }) => (
            <p className="content-paragraph">{children}</p>
        )
    };

    return (
        <div className="generated-content">
            {isEditing ? (
                <textarea
                    ref={textAreaRef}
                    className="edit-textarea"
                    value={editableContent}
                    onChange={(e) => setEditableContent(e.target.value)}
                    placeholder="Edit your content here..."
                />
            ) : (
                <ReactMarkdown components={components}>
                    {formatContent(generatedContent)}
                </ReactMarkdown>
            )}
        </div>
    );
};

export default GeneratedContent;
import React, { useState, useEffect, useCallback } from 'react';
import './SaveToContacts.css';

const OnePgrSaveInterface = ({ handleSaveConfirm, onCancel }) => {
  const [listType, setListType] = useState('new');
  const [listName, setListName] = useState('');
  const [contactList, setContactList] = useState([]);
  const [selectedContactList, setSelectedContactList] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedListId, setSelectedListId] = useState(null);
  console.log(" contactList-->",selectedContactList)

  const handleListSelect = (e) => {
    const listId = e.target.value;
    setSelectedContactList(listId);
  };

  const handleContactSave = () => {
    if (listType === 'new') {
      if (!listName.trim()) {
        setError(' enter a list name');
        return;
      }
      handleSaveConfirm({ type: 'new', name: listName });
    } else {
      if (!selectedContactList) {
        setError(' select a contact list');
        return;
      }
      const selectedList = contactList.find(list => list.list_id === selectedContactList);
      handleSaveConfirm({
        type: 'existing',
        id: selectedContactList,
        name: selectedList?.list_name
      });
    }
  };

  const fetchOnepgrContactList = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    const url = 'https://onepgr.com/lists/index_api';

    const formData = new FormData();
    formData.append('onepgr_apicall', '1');
    formData.append('xhr_flag', '1');
    formData.append('list_type', 'contact');

    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Accept-Language': 'en-GB,en;q=0.9',
        'Connection': 'keep-alive',
        'Origin': 'https://meet.onepgr.com',
        'Referer': 'https://meet.onepgr.com/',
        'Sec-Fetch-Dest': 'empty',
        'Sec-Fetch-Mode': 'cors',
        'Sec-Fetch-Site': 'same-site',
        'User-Agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Safari/537.36',
        'sec-ch-ua': '"Chromium";v="128", "Not;A=Brand";v="24", "Google Chrome";v="128"',
        'sec-ch-ua-mobile': '?0',
        'sec-ch-ua-platform': '"macOS"'
      },
      body: formData,
      credentials: 'include'
    };

    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.success === "0" && data.response && data.response.lists_listing) {
        setContactList(data.response.lists_listing);
      } else {
        throw new Error('Unexpected data structure in response');
      }
    } catch (error) {
      console.error('There was a problem fetching the contact list:', error);
      setError('Failed to fetch contact lists.  try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchOnepgrContactList();
  }, [fetchOnepgrContactList]);

  return (
    <div className="onepgr-save-overlay">
      <div className="onepgr-save-modal">
        <h2>Save to OnePgr Contact List</h2>
        <div className="onepgr-radio-group">
          <label>
            <input
              type="radio"
              value="new"
              checked={listType === 'new'}
              onChange={() => setListType('new')}
            />
            Create new Contact List
          </label>
          <label>
            <input
              type="radio"
              value="existing"
              checked={listType === 'existing'}
              onChange={() => setListType('existing')}
            />
            Use existing Contact List
          </label>
        </div>
        {listType === 'new' ? (
          <div className="onepgr-input-group">
            <label htmlFor="listName">Enter List Name:</label>
            <input
              type="text"
              id="listName"
              value={listName}
              onChange={(e) => setListName(e.target.value)}
              placeholder="Enter list name"
            />
          </div>
        ) : (
          <div className="onepgr-input-group">
            <label htmlFor="existingList">Select Existing Contact List:</label>
            {isLoading ? (
              <p>Loading contact lists...</p>
            ) : error ? (
              <p className="error">{error}</p>
            ) : (
              <select
                id="existingList"
                value={selectedContactList}
                onChange={handleListSelect}
              >
                <option value="">Select a list</option>
                {contactList.map((list) => (
                  <option key={list.list_id} value={list.list_id}>
                    {list.list_name}
                  </option>
                ))}
              </select>
            )}
          </div>
        )}
        <div className="onepgr-button-group">
          <button className="onepgr-cancel-btn" onClick={onCancel}>
            Cancel
          </button>
          <button
            className="onepgr-save-btn"
            onClick={handleContactSave}
            disabled={
              (listType === 'new' && !listName) ||
              (listType === 'existing' && !selectedContactList) ||
              isLoading
            }
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnePgrSaveInterface;
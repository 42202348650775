import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css'; // Change this import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faTrash, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import '../styles/ContentComposer.css';

const ContentComposer = ({ content, type }) => {
  const [bodyContent, setBodyContent] = useState('');
  const [subjectContent, setSubjectContent] = useState('');
  const [timeOffset, setTimeOffset] = useState(0);

  useEffect(() => {
    if (content) {
      // console.log("content quil editor--->",content)
      // Check if the content is in the Gen AI Prompt format
      if (typeof content === 'object' && content.subject && content.body) {
        setSubjectContent(content.subject);
        setBodyContent(`<p>${content.body}</p>`);
        // Handle the previous format
        const lines = content.body.split('\n');
        let subjectLine = '';
        let bodyLines = [];
        let isBody = false;
        lines.forEach(line => {
          if (line.toLowerCase().startsWith('subject:')) {
            // subjectLine = line.replace(/^subject:/i, '').trim();
          } else if (isBody || line.trim() !== '') {
            isBody = true;
            bodyLines.push(line);
          }
        });

        // setSubjectContent(subjectLine);
        
        // Format body content for Quill
        const formattedBody = bodyLines.map(line => {
          if (line.trim() === '') {
            return '<p><br></p>';
          }
          return `<p>${line}</p>`;
        }).join('');

        setBodyContent(formattedBody);
    }
  }
  }, [content]);

  const handleBodyChange = (content) => {
    setBodyContent(content);
  };

  const handleSubjectChange = (e) => {
    setSubjectContent(e.target.value);
  };

  const handleTimeOffsetChange = (e) => {
    setTimeOffset(e.target.value);
  };

  return (
    <div className="content-composer">
      <div className="composer-header">
        <h2 >STEP: {type.toUpperCase()}</h2>
        {/* <div className="composer-actions">
          <FontAwesomeIcon icon={faCopy} className="action-icon" />
          <FontAwesomeIcon icon={faTrash} className="action-icon" />
          <FontAwesomeIcon icon={faPaperPlane} className="action-icon" />
          <div className="toggle-switch">
            <span>Off</span>
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
            <span>On</span>
          </div>
          <button className="a-b-button">A/B</button>
        </div> */}
      </div>
      
      <div className="composer-settings">
        <div className="time-offset">
          <label>Time Offset:</label>
          <input type="number" value={timeOffset} onChange={handleTimeOffsetChange} />
          <span>Hours</span>
        </div>
        {/* <div className="email-type">
          <label>
            <input type="radio" name="emailType" value="newEmail" defaultChecked />
            New Email
          </label>
        </div> */}
      </div>

      <div className="subject-line">
      <label>Subject: </label>
        <input 
          type="text" 
          value={subjectContent} 
          onChange={handleSubjectChange} 
          placeholder="Subject" 
        />
        
      </div>

      <ReactQuill 
        theme="snow" 
        value={bodyContent} 
        onChange={handleBodyChange}
        modules={{
          toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
          ],
        }}
      />
    </div>
  );
};

export default ContentComposer;
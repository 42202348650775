// Add this new file for research-related API calls
const API_IP = 'chatserver.onepgr.com';
// const API_IP = '127.0.0.1:5000';
const API_PROTOCOL = 'https';
const API_BASE_URL = `${API_PROTOCOL}://${API_IP}`;

export const prospectResearch = async (prospectData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/research/prospect`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: prospectData.name,
        company: prospectData.company,
        title: prospectData.title,
      }),
    });

    if (!response.ok) {
      throw new Error('Prospect research failed');
    }

    return await response.json();
  } catch (error) {
    console.error('Prospect research error:', error);
    throw error;
  }
};

export const companyResearch = async (companyData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/research/company`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: companyData.name,
        industry: companyData.industry,
      }),
    });

    if (!response.ok) {
      throw new Error('Company research failed');
    }

    return await response.json();
  } catch (error) {
    console.error('Company research error:', error);
    throw error;
  }
}; 

export const langFlowProspectResearch = async (prospectData) => {
    console.log("Prospect Data:", prospectData);
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/process/64c5c908-a785-43c2-a2b6-a53bbb64e744`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inputs: prospectData.inputs.input,
          tweaks: {
            "ChatOpenAI-6OQof": {},
            "PromptTemplate-BxRo6": {},
            "LLMChain-G3AuZ": {},
            "StructuredOutputParser-29uwH": {},
            "ResponseSchema-ssl3m": {},
          },
        }),
      });
  
      if (!response.ok) {
        throw new Error('Prospect research failed');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Prospect research error:', error);
      throw error;
    }
  };
  export const langFlowCompanyResearch = async (companyData) => {
    console.log("Company Data:", companyData);
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/process/40f9111c-429f-4d56-a0f2-00e7f3361aa2`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inputs: companyData.inputs.input, 
          tweaks: {
            "ResponseSchema-ugRCv": {},
            "StructuredOutputParser-Iz7tz": {},
            "PromptTemplate-hxHHM": {},
            "ChatOpenAI-AGAHX": {},
            "LLMChain-wR2Jt": {},
          },
        }),
      });
  
      if (!response.ok) {
        throw new Error('Company research failed');
      }
  
      return await response.json();
    } catch (error) {
      console.error('Company research error:', error);
      throw error;
    }
  };
import React, { useState } from 'react';
import '../styles/contentDisplay.css';
import {
  FaClipboard,
  FaShareAlt,
  FaLinkedin,
  FaMailBulk,
  FaShare,
  FaFileAlt,
  FaDownload,
} from 'react-icons/fa';

const ContentDisplay = ({ content }) => {
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  // Extract fields from the content object
  const {
    content: mainContent = 'No content available.',
    rawResponse = {},
    message: statusMessage = 'Content has been generated!',
  } = content || {};

  const {
    next_steps = 'No next steps provided.',
    content: rawContent = mainContent,
    meta: metaString = '',
    seo_tips = 'No SEO tips provided.',
  } = rawResponse;

  // Parse meta string into an object
  const parseMeta = (metaString) => {
    const metaObject = {};
    metaString.split('\n').forEach((line) => {
      const [key, value] = line.split(':').map((item) => item.trim());
      if (key && value) {
        metaObject[key.toLowerCase()] = value;
      }
    });
    return metaObject;
  };

  const meta = parseMeta(metaString);
  const { title = 'Untitled', keywords = '' } = meta;

  // Handle Copy to Clipboard
  const handleCopy = () => {
    const fullContent = `
      **${title}**
      
      \n${rawContent}
      \n\n**SEO Tips:**\n${seo_tips}
      \n\n**Next Steps:**\n${next_steps}
    `;
    navigator.clipboard.writeText(fullContent);
    setCopySuccess('Copied!');
    setTimeout(() => setCopySuccess(''), 2000); // Reset after 2 seconds
  };

  // Handle Share Options
  const handleShare = (platform) => {
    const shareText = `${title}\n\n${rawContent}`;
    switch (platform) {
      case 'linkedin':
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}&summary=${encodeURIComponent(shareText)}`,
          '_blank'
        );
        break;
      case 'email':
        window.location.href = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(shareText)}`;
        break;
      case 'native':
        if (navigator.share) {
          navigator.share({
            title: title,
            text: shareText,
            url: window.location.href,
          });
        } else {
          alert('Web Share API not supported in your browser.');
        }
        break;
      case 'landing-page':
        alert('Create Landing Page functionality goes here.');
        break;
      default:
        break;
    }
  };

  // Handle Download Options
  const downloadAsTxt = () => {
    const fullContent = `
      **${title}**
     
      \n${rawContent}
      \n\n**SEO Tips:**\n${seo_tips}
      \n\n**Next Steps:**\n${next_steps}
    `;
    const blob = new Blob([fullContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'content.txt';
    link.click();
    URL.revokeObjectURL(url);
  };

  const downloadAsPdf = () => {
    alert('PDF download functionality goes here.');
  };

  return (
    <div className="content-container">
      {/* Title */}
      <h1 className="content-title">{title}</h1>

      {/* Main Content */}
      <div className="content-main-content">
        {rawContent.split('\n').map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>

      {/* SEO Tips */}
      <div className="content-section">
        <h2 className="content-section-title">SEO Tips</h2>
          <div className="content-section-content">
          {seo_tips.split('\n').map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
      </div>

      {/* Next Steps */}
      <div className="content-section">
        <h2 className="content-section-title">Next Steps</h2>
        <div className="content-section-content">
          {next_steps.split('\n').map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
      </div>

      {/* Action Buttons */}
      <div className="action-buttons">
        <button className="action-button" onClick={handleCopy} title={copySuccess || 'Copy to clipboard'}>
          <FaClipboard /> Copy
        </button>
        <div className="content-dropdown-container">
          <button className="action-button" onClick={() => setShowShareMenu(!showShareMenu)} title="Share content">
            <FaShareAlt /> Share
          </button>
          {showShareMenu && (
            <div className="content-dropdown-menu">
              <button onClick={() => handleShare('linkedin')}>
                <FaLinkedin /> LinkedIn
              </button>
              <button onClick={() => handleShare('email')}>
                <FaMailBulk /> Email
              </button>
              <button onClick={() => handleShare('native')}>
                <FaShare /> Share
              </button>
              <button onClick={() => handleShare('landing-page')}>
                <FaFileAlt /> Create Landing Page
              </button>
            </div>
          )}
        </div>
        <div className="content-dropdown-container">
          <button className="action-button" onClick={() => setShowDownloadOptions(!showDownloadOptions)} title="Download options">
            <FaDownload /> Download
          </button>
          {showDownloadOptions && (
            <div className="content-dropdown-menu">
              <button onClick={downloadAsTxt}>Text File (.txt) 📄</button>
              <button onClick={downloadAsPdf}>PDF Document (.pdf) 📑</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentDisplay;
import React, { useState } from 'react';
import { ChevronDown, ChevronUp, MapPin, ExternalLink ,Briefcase} from 'lucide-react';
import '../styles/linkedinJobListings.css';

const LinkedInJobListings = ({ jobData }) => {
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  // Filter out invalid job data
  const validJobs = jobData || [];

  if (!validJobs.length) {
    return (
      <div className="linkedinJobContainer">
        <div className="linkedinJobHeader">
          <h2 className="linkedinJobTitle">No valid job listings found</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="linkedinJobContainer">
      <div className="linkedinJobHeader">
      <h2 className="linkedinJobTitle">
          <Briefcase className="jobIcon" /> {/* Job Icon */}
          Job Listings ({validJobs.length})
        </h2>
      </div>

      <div className="linkedinJobList">
        {validJobs.map((job, index) => (
          <div key={index} className="linkedinJob">
            <div className="jobPreview">
              <div className="jobLogo">
                {job.company.logo ? (
                  <img src={job.company.logo} alt={job.company.name} className="companyLogo" />
                ) : (
                  <div className="placeholderLogo">Logo</div>
                )}
              </div>
              <div className="jobInfo">
                <div className="jobTitle">{job.title}</div>
                <div className="jobCompany">{job.company.name}</div>
                <div className="jobLocation">
                  <MapPin size={14} />
                  {job.location}
                </div>
                <div className="jobSalary">{job.benefits}</div>
              </div>
            </div>

            {expandedSection === index && (
              <div className="jobExpanded">
                <div className="jobDetails">
                  <div className="jobPostDate">
                    <strong>Posted on:</strong> {new Date(job.postAt).toLocaleDateString()}
                  </div>
                  <div className="jobLink">
                    <a
                      href={job.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="profileLink"
                    >
                      <ExternalLink size={14} />
                      View Job Posting
                    </a>
                  </div>
                </div>
              </div>
            )}

            <button
              className="linkedinJobListingToggleButton"
              onClick={() => toggleSection(index)}
            >
              {expandedSection === index ? (
                <>
                  <ChevronUp size={16} />
                  Show less
                </>
              ) : (
                <>
                  <ChevronDown size={16} />
                  Show more
                </>
              )}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LinkedInJobListings;

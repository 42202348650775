import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Users, ExternalLink, MapPin } from 'lucide-react';
import '../styles/linkenidinEventAttendees.css';

const LinkedInEventAttendees = ({ eventData }) => {
  const [expandedSection, setExpandedSection] = useState(null);

  // Filter out attendees with errors
  const validAttendees = eventData?.guests?.filter(attendee => !attendee.error) || [];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  if (!validAttendees.length) {
    return (
      <div className="linkedinEventContainer">
        <div className="linkedinEventHeader">
          <h2 className="linkedinEventTitle">No valid attendees found</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="linkedinEventContainer">
      <div className="linkedinEventHeader">
        <h2 className="linkedinEventTitle">
          <Users className="eventIcon" />
          Event Attendees ({validAttendees.length})
        </h2>
      </div>
      
      <div className="linkedinEventAttendeesList">
        {validAttendees.map((attendee) => (
          <div key={attendee._id} className="linkedinEventAttendee">
            <div className="attendeePreview">
              <div className="attendeeAvatar">
                {attendee.profileImageUrl ? (
                  <img 
                    src={attendee.profileImageUrl} 
                    alt={attendee.fullName} 
                    className="avatarImage"
                  />
                ) : (
                  attendee.firstName?.[0]?.toUpperCase() || '?'
                )}
              </div>
              
              <div className="attendeeInfo">
                <div className="attendeeHeader">
                  <div>
                    <div className="attendeeName">
                      {attendee.fullName}
                    </div>
                    <div className="attendeeTitle">{attendee.job}</div>
                  </div>
                  
                  
                </div>
                
                
                {attendee.location && (
                  <div className="attendeeLocation">
                    <MapPin size={14} />
                    {attendee.location}
                  </div>
                )}
              </div>
              
            </div>
          
            {expandedSection === attendee._id && (
              <div className="attendeeExpanded">
                <div className="attendeeDetails">
                  <div className="attendeeConnection">
                    Connection Degree: {attendee.degree}
                  </div>
                  
                  
                  {attendee.profileUrl && (
                    <div className="attendeeLinks">
                      <a 
                        href={attendee.profileUrl} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="profileLink"
                      >
                        <ExternalLink size={14} />
                        View LinkedIn Profile
                      </a>
                    </div>
                  )}
                  
                </div>
              </div>
            )}
              <button 
                    className="linkedinEventAttendeestoggleButton"
                    onClick={() => toggleSection(attendee._id)}
                  >
                    {expandedSection === attendee._id ? (
                      <>
                        <ChevronUp size={16} />
                        Show less
                      </>
                    ) : (
                      <>
                        <ChevronDown size={16} />
                        Show more
                      </>
                    )}
                  </button>
            
          </div>
          
        ))}
        
      </div>
      
    </div>
  );
};

export default LinkedInEventAttendees;
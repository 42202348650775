import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Clock, CheckCircle2, ExternalLink } from 'lucide-react';
import '../styles/linkedinMessagesDisplay.css';

const LinkedInMessagesDisplay = ({ messagesData }) => {
  const [expandedThread, setExpandedThread] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const toggleThread = (threadUrl) => {
    setExpandedThread(expandedThread === threadUrl ? null : threadUrl);
  };

  // Extract the resultObject from the nested structure
  const messages = messagesData?.inboxData?.resultObject || [];
  
  if (!Array.isArray(messages) || messages.length === 0) {
    console.warn('Invalid or missing LinkedIn messages data');
    return (
      <div className="linkedinMessagesContainer">
        <div className="linkedinMessagesHeader">
          <h2 className="linkedinMessagesTitle">No messages found</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="linkedinMessagesContainer">
      <div className="linkedinMessagesHeader">
        <h2 className="linkedinMessagesTitle">LinkedIn Messages ({messages.length})</h2>
      </div>
      
      <div className="linkedinMessagesThreadList">
        {messages.map((thread, index) => (
          <div key={thread.threadUrl || index} className="linkedinMessagesThread">
            <div 
              onClick={() => toggleThread(thread.threadUrl)}
              className="linkedinMessagesPreview"
            >
              <div className="linkedinMessagesAvatar">
                {thread.firstnameFrom?.[0]?.toUpperCase() || '?'}
              </div>
              
              <div className="linkedinMessagesContent">
                <div className="linkedinMessagesContentHeader">
                  <div className="linkedinMessagesSenderInfo">
                    <h3>
                      {thread.firstnameFrom} {thread.lastnameFrom}
                    </h3>
                    <p className="linkedinMessagesSenderTitle">
                      {thread.occupationFrom}
                    </p>
                  </div>
                  <div className="linkedinMessagesMetadata">
                    <Clock size={14} />
                    <span>{formatDate(thread.lastMessageDate)}</span>
                    {thread.readStatus && (
                      <div className="linkedinMessagesReadStatus">
                        <CheckCircle2 size={14} />
                        <span>Read</span>
                      </div>
                    )}
                  </div>
                </div>
                
                <div className="linkedinMessagesPreviewText">
                  {thread.message ? thread.message.replace(/<[^>]*>/g, '') : 'No message content'}
                </div>

                {expandedThread === thread.threadUrl && (
              <div className="linkedinMessagesExpanded">
                <div className="linkedinMessagesBody">
                  {thread.message ? (
                    <div 
                      dangerouslySetInnerHTML={{ 
                        __html: thread.message 
                      }} 
                    />
                  ) : (
                    <p>No message content available</p>
                  )}
                  
                  <div className="linkedinMessagesFooter">
                    <div>Last message: {formatDate(thread.lastMessageDate)}</div>
                    {thread.linkedInUrls?.[0] && (
                      <a 
                        href={thread.linkedInUrls[0]} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="linkedinMessagesProfileLink"
                      >
                        <ExternalLink size={14} />
                        View LinkedIn Profile
                      </a>
                    )}
                  </div>
                </div>
              </div>
            )}
                
                <button 
                  className="linkedinMessagesToggleButton"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleThread(thread.threadUrl);
                  }}
                >
                  {expandedThread === thread.threadUrl ? (
                    <>
                      <ChevronUp size={16} />
                      Show less
                    </>
                  ) : (
                    <>
                      <ChevronDown size={16} />
                      Show more
                    </>
                  )}
                </button>
              </div>
            </div>

          
          </div>
        ))}
      </div>
    </div>
  );
};

export default LinkedInMessagesDisplay;
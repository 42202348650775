import React, { useState, useEffect, useRef } from "react";
import "./actionPoint.css";
import { FaChevronLeft, FaChevronRight, FaUser } from 'react-icons/fa';
import { FaLinkedin, FaBlog, FaBullseye, FaVideo, FaComments, FaEnvelope, FaBullhorn, FaBox, FaChartBar, FaSearch, FaPencilAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useCombinedContext } from '../context/userContext';
import NavBar from '../navBar';
import Sidebar from '../sideBar';
import { createNewChat } from '../../api/api';

const contentTypes = [
    { id: 1, name: "Prospect Research", icon: <FaUser/>, description: "Research on prospects ", type: "research" },
    { id: 2, name: "Blog Writer", icon: <FaBlog />, description: "Write blog post on AI", type: "writer app" },
    { id: 3, name: "System Events", icon: <FaSearch />, description: "System maintenance", type: "system events" },
    { id: 4, name: "Company Research", icon: <FaBullseye />, description: "Research on companies", type: "research" },
    { id: 5, name: "Build a list", icon: <FaVideo />, description: "Build a list of contacts", type: "research" },
    { id: 6, name: "LinkedIn post", icon: <FaComments />, description: "Create a LinkedIn post", type: "writer app" },
    { id: 7, name: "Task 7", icon: <FaEnvelope />, description: "Monitor server logs", type: "system events" },
    { id: 8, name: "Task 8", icon: <FaBullhorn />, description: "Conduct user interviews", type: "research" },
    { id: 9, name: "Warmly", icon: <FaBullhorn />, description: "Track and manage Warmly leads", type: "inbound leads" },
    { id: 10, name: "RB2B", icon: <FaBullhorn />, description: "Track and manage RB2B leads", type: "inbound leads" }
];

const ActionPoint = ({ onLogout }) => {
    const [activeTab, setActiveTab] = useState("all");
    const [currentPage, setCurrentPage] = useState(0);
    const [buttonsPerPage, setButtonsPerPage] = useState(8);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [recentSessions, setRecentSessions] = useState([]);
    const [activeSessionId, setActiveSessionId] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const sidebarRef = useRef(null);
    const sidebarTriggerRef = useRef(null);
    const navigate = useNavigate();
    const { user, setUser } = useCombinedContext();
   
    const sessionType = "action_point";

    // Filter contentTypes based on active tab
    const filteredContentTypes = activeTab === "all" 
        ? contentTypes 
        : contentTypes.filter((type) => type.type === activeTab);

    // Pagination logic
    const totalPages = Math.ceil(filteredContentTypes.length / buttonsPerPage);

    const handleCardScroll = (direction) => {
        if (direction === 'right' && currentPage < totalPages - 1) {
            setCurrentPage(curr => curr + 1);
        } else if (direction === 'left' && currentPage > 0) {
            setCurrentPage(curr => curr - 1);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1400) {
                setButtonsPerPage(8); // 4x2 grid
            } else if (window.innerWidth >= 1000) {
                setButtonsPerPage(6); // 3x2 grid
            } else {
                setButtonsPerPage(4); // 2x2 grid
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isSidebarOpen && sidebarTriggerRef.current) {
                const triggerRect = sidebarTriggerRef.current.getBoundingClientRect();
                if (e.clientX <= triggerRect.right && e.clientY >= triggerRect.top && e.clientY <= triggerRect.bottom) {
                    setIsSidebarOpen(true);
                }
            }
        };

        document.addEventListener('mousemove', handleMouseMove);
        return () => document.removeEventListener('mousemove', handleMouseMove);
    }, [isSidebarOpen]);

    const handleSidebarMouseLeave = (e) => {
        if (sidebarRef.current && e.clientX > sidebarRef.current.getBoundingClientRect().right) {
            setIsSidebarOpen(false);
        }
    };

    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

    const handleLogout = () => {
        setUser(null);
        onLogout();
    };

    const handleNewSession = async () => {
        try {
            const newSessionId = await createNewChat(user.userId, sessionType);
            setActiveSessionId(newSessionId);
            navigate(`/action-point/${newSessionId}`);
        } catch (error) {
            console.error('Error creating new session:', error);
        }
    };

    const getAvatarContent = () => {
        if (!user) return '';
        if (user.avatar) return <img src={user.avatar} alt="User avatar" className="user-avatar-img" />;
        return user.name.charAt(0).toUpperCase();
      };

    const handleContentTypeClick = (type) => {
        setSelectedType(type);
        
        // Convert content type name to URL-friendly format
        const urlPath = type.name.toLowerCase().replace(/\s+/g, '-');
        
        // Different behaviors based on content type
        switch (type.name) {
            case "Prospect Research":
                // Navigate to prospect research with cards UI
                navigate(`/action-point/prospect-research`);
                break;
            case "Blog Writer":
                navigate(`/action-point/blog-writer`);
                break;
            case "System Events":
                navigate(`/action-point/system-events`);
                break;
            case "LinkedIn post":
                navigate(`/action-point/linkedin-post`);
                break;
            case "Warmly":
                navigate(`/action-point/warmly`);
                break;
            case "RB2B":
                navigate(`/action-point/rb2b`);
                break;
            case "Company Research":
                navigate(`/action-point/company-research`);
                break;
            case "Build a list":
                navigate(`/action-point/build-list`);
                break;
            default:
                // Default navigation pattern
                navigate(`/action-point/${urlPath}`);
        }
    };

    // Add a back button handler that maintains the routing pattern
    const handleBack = () => {
        setSelectedType(null);
        navigate('/action-point');
    };

    return (
        <div className="action-point-container">
            <NavBar toggleSidebar={toggleSidebar} onLogout={handleLogout} />
            
            <div
                ref={sidebarTriggerRef}
                className="sidebar-trigger"
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    width: '10px',
                    height: '100%',
                    zIndex: 100
                }}
            />

            <div
                ref={sidebarRef}
                className={`sidebar-wrapper ${isSidebarOpen ? 'open' : ''}`}
                onMouseLeave={handleSidebarMouseLeave}
            >
                <Sidebar
                    onLogout={handleLogout}
                    onNewChat={handleNewSession}
                    userId={user?.userId}
                    recentChats={recentSessions}
                    activeSessionId={activeSessionId}
                    onChatSelect={(sessionId) => navigate(`/action-point/${sessionId}`)}
                />
            </div>

            <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                <div className="action-point-container-inner">
                    {!selectedType || (selectedType.name !== "Prospect Research" && selectedType.name !== "Company Research") ? (
                        <>
                            <div className="welcome-text">
                                <h1>Manage Your Tasks in Action Point</h1>
                                <p>Stay organized and track your progress effortlessly.</p>
                            </div>

                            <nav className="action-point-nav">
                                <button
                                    className={activeTab === "all" ? "active" : ""}
                                    onClick={() => setActiveTab("all")}
                                >
                                    All
                                </button>
                                <button
                                    className={activeTab === "research" ? "active" : ""}
                                    onClick={() => setActiveTab("research")}
                                >
                                    Research
                                </button>
                                <button
                                    className={activeTab === "writer app" ? "active" : ""}
                                    onClick={() => setActiveTab("writer app")}
                                >
                                    Writer App
                                </button>
                                <button
                                    className={activeTab === "system events" ? "active" : ""}
                                    onClick={() => setActiveTab("system events")}
                                >
                                    System Events
                                </button>
                                <button
                                    className={activeTab === "inbound leads" ? "active" : ""}
                                    onClick={() => setActiveTab("inbound leads")}
                                >
                                    Inbound Leads
                                </button>
                                <button
                                    className={activeTab === "calendar" ? "active" : ""}
                                    onClick={() => setActiveTab("calendar")}
                                >
                                    Calendar
                                </button>
                                <button
                                    className={activeTab === "meeting recordings" ? "active" : ""}
                                    onClick={() => setActiveTab("meeting recordings")}
                                >
                                    Meeting Recordings
                                </button>
                            </nav>

                            <div className="action-point-types-container">
                                <button
                                    className="action-point-scroll-button action-point-scroll-left"
                                    onClick={() => handleCardScroll('left')}
                                    disabled={currentPage === 0}
                                >
                                    <FaChevronLeft />
                                </button>

                                <div className="action-point-content-types-wrapper">
                                    <div
                                        className="action-point-content-types-grid-container"
                                        style={{
                                            transform: `translateX(-${currentPage * 100}%)`,
                                            display: 'flex'
                                        }}
                                    >
                                        {Array.from({ length: totalPages }).map((_, pageIndex) => (
                                            <div key={pageIndex} className="action-point-content-types-page">
                                                {filteredContentTypes
                                                    .slice(pageIndex * buttonsPerPage, (pageIndex + 1) * buttonsPerPage)
                                                    .map((type) => (
                                                        <button
                                                            key={type.id}
                                                            className="action-point-content-type-btn"
                                                            onClick={() => handleContentTypeClick(type)}
                                                        >
                                                            <span className="action-point-content-type-icon">{type.icon}</span>
                                                            <span className="action-point-content-type-name">{type.name}</span>
                                                            <span className="action-point-content-type-description">{type.description}</span>
                                                        </button>
                                                    ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <button
                                    className="action-point-scroll-button action-point-scroll-right"
                                    onClick={() => handleCardScroll('right')}
                                    disabled={currentPage >= totalPages - 1}
                                >
                                    <FaChevronRight />
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="selected-type-view">
                            <div className="selected-type-header">
                                <button className="back-button" onClick={handleBack}>
                                    <FaChevronLeft /> Back
                                </button>
                                <div className="selected-type-title">
                                    <span className="selected-type-icon">{selectedType.icon}</span>
                                    <h1>{selectedType.name}</h1>
                                </div>
                            </div>
                            <div className="content-cards">
                                {selectedType.name === "Prospect Research" && [
                                    {id: 1, title: "Find Prospects", description: "Search and discover new potential prospects"},
                                    {id: 2, title: "View Prospects", description: "Browse and review your existing prospects"},
                                    {id: 3, title: "Import Prospects List", description: "Upload and import list of prospects from external sources"},
                                    {id: 4, title: "Find prospect who liked and commented", description: "Identify prospects who engaged with your content"},
                                    {id: 5, title: "Competitive Analysis", description: "Analyze and compare prospects against competitors"}
                                ].map((card) => (
                                    <div key={card.id} className="content-card">
                                        <h3>{card.title}</h3>
                                        <p>{card.description}</p>
                                    </div>
                                ))}
                                {selectedType.name === "Company Research" && [
                                    {id: 1, title: "Company Overview", description: "Get detailed company information and key metrics"},
                                    {id: 2, title: "Financial Analysis", description: "Review financial performance and metrics"},
                                    {id: 3, title: "Market Position", description: "Analyze market share and competitive landscape"},
                                    {id: 4, title: "Leadership Team", description: "Research key executives and decision makers"},
                                    {id: 5, title: "News & Updates", description: "Latest company news and developments"}
                                ].map((card) => (
                                    <div key={card.id} className="content-card">
                                        <h3>{card.title}</h3>
                                        <p>{card.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {user && (
        <div className={`user-info-avatar ${isSidebarOpen ? 'hidden' : ''}`} >
          <div className="user-fixed-avatar" onClick={toggleSidebar}>
            {getAvatarContent()}
          </div>
        </div>
      )}
        </div>
    );
};

export default ActionPoint;
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import '../styles/navBar.css';

const NavBar = ({ toggleSidebar, onLogout }) => {
  const [isStarred, setIsStarred] = useState(false);
  const location = useLocation();

  const toggleStar = () => {
    setIsStarred(!isStarred);
  };

  // Check if the current path is /profile/settings or /writerai
  const shouldShowStar = !['/profile/settings', '/writerai'].includes(location.pathname);

  return (
    <nav className="navbar">
      <div className="navbar-content">
        <div className="navbar-brand">
          <h1 className="navbar-header">getSalesGPT</h1>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="menu-icon"
            onClick={() => toggleSidebar(prevState => !prevState)}
          >
            <rect x="3" y="4" width="18" height="16" rx="2" stroke="currentColor" strokeWidth="2" />
            <path d="M9 4V20" stroke="currentColor" strokeWidth="2" />
          </svg>
        </div>
        {shouldShowStar && (
          <button className="star-button" onClick={toggleStar}>
            <FontAwesomeIcon 
              icon={isStarred ? fasStar : farStar} 
              className={isStarred ? 'starred' : ''}
              title='Star chat'
            />
          </button>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const API_IP = 'chatserver.onepgr.com';
// const API_IP = 'https://127.0.0.1:5000';
const API_PROTOCOL = 'https';
const API_BASE_URL = `${API_PROTOCOL}://${API_IP}`;
const FLOW_ID = '526da93a-028e-4e9b-804d-dfdc9c3db47f';

// Generic function to handle API errors
const handleApiError = (error, customMessage = 'API call failed') => {
  console.error(customMessage, error);
  if (error.response) {
    console.error('Error response:', error.response.data);
  }
  throw error;
};

// Helper function to format content as markdown
const formatContentAsMarkdown = (input) => {
  if (!input) return '';

  try {
    // Handle case where input is already an object
    const contentObj = typeof input === 'object' ? input : { content: input };

    // Extract title from meta field
    const cleanMeta = contentObj.meta?.replace(/\\n/g, '\n') || '';
    const titleMatch = cleanMeta.match(/Title:\s*([^|]+)/);
    const title = titleMatch ? titleMatch[1].trim() : 'Generated Content';

    // Clean up content
    const cleanContent = contentObj.content?.replace(/\\n/g, '\n')
      .split('\n')
      .map(line => line.trim())
      .filter(Boolean)
      .join('\n\n');

    // Clean up additional sections
    const cleanSeoTips = contentObj.seo_tips?.replace(/\\n/g, '\n').trim();
    const cleanNextSteps = contentObj.next_steps?.replace(/\\n/g, '\n').trim();

    // Build formatted markdown
    let formattedContent = `# ${title}\n\n`;
    formattedContent += cleanContent ? `${cleanContent}\n\n` : '';
    formattedContent += cleanSeoTips ? `## SEO Tips\n${cleanSeoTips}\n\n` : '';
    formattedContent += cleanNextSteps ? `## Next Steps\n${cleanNextSteps}\n\n` : '';
    formattedContent += cleanMeta ? `## Metadata\n${cleanMeta}` : '';

    return formattedContent;
  } catch (error) {
    console.error('Error formatting content:', error);
    return typeof input === 'string' ? input : JSON.stringify(input, null, 2);
  }
};
// Main API function for content generation and chat
export const processContentRequest = async ({
  sessionId,
  input,
  contentType = 'Custom Content',
  audience = 'general',
  length = 'medium',
  tone = 'Professional',
  seedIdea = '',
  userId
}) => {
  try {
    const payload = {
      inputs: {
        audience,
        content_type: contentType,
        input,
        length,
        platform: 'medium',
        seed_idea: seedIdea || input,
        tone,
        session_id: sessionId
      },
      tweaks: {
        "MongoDBChatMessageHistory-bfHWw": {
          "Collection Name": "writerAiMessages",
          "Connection String": "mongodb://username:db1234@pages.onepgr.com",
          "Database Name": "onepgr",
          "session_id": sessionId
        },
        "PromptTemplate-bhPC9": {},
        "ChatOpenAI-vZ7Bu": {},
        "LLMChain-e3k4A": {},
        "ResponseSchema-GLAKN": {},
        "ResponseSchema-eHp1l": {},
        "StructuredOutputParser-srLqm": {},
        "ResponseSchema-OzYBz": {},
        "ResponseSchema-e84Iq": {},
        "ConversationBufferMemory-F5eX2": {}
      }
    };

    const response = await axios.post(
      `${API_BASE_URL}/api/v1/process/${FLOW_ID}`,
      payload,
      {
        withCredentials: false,  // Remove or set to false
        // ... other config options
      }
    );

    // Update database with the new message
    await updateWriterAiMessage({
      query: {
        SessionId: sessionId
      },
      updateData: {
        user_id: userId,
        chat_type: "writerAi",
        chat_content_type: contentType
      }
    });

    if (response.data?.result?.text) {
      let formattedResponse;

      if (response.data.result.text.includes('```json')) {
        try {
          const jsonText = response.data.result.text.replace(/```json\n?/, '').replace(/\n?```$/, '');
          const parsedOutput = JSON.parse(jsonText);
          const formattedContent = formatContentAsMarkdown(parsedOutput);

          formattedResponse = {
            content: formattedContent,
            type: 'structured',
            rawResponse: parsedOutput,
            message: "Content has been generated! Check right panel to view full details."
          };
        } catch (e) {
          console.error('Error parsing JSON response:', e);
          const formattedContent = formatContentAsMarkdown({ content: response.data.result.text });

          formattedResponse = {
            content: formattedContent,
            type: 'text',
            message: "Content updated. Let me know if you'd like any changes."
          };
        }
      } else {
        formattedResponse = {
          content: response.data.result.text,
          type: 'text',
          message: response.data.result.text
        };
      }

      return formattedResponse;
    }

    throw new Error('Invalid response format');
  } catch (error) {
    handleApiError(error, 'Error processing content request');
  }
};

// Update Writer AI Message in database
export const updateWriterAiMessage = async (payload) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/chat/updateWriterAiMessage`,
      payload
    );

    console.log("Database update response:", response.data);
    return response.data;
  } catch (error) {
    handleApiError(error, 'Error updating writer AI message');
  }
};

export const deleteWriterAiSession = async (sessionId) => {
  console.log("Deleting writer AI session:", sessionId);
  try {
    const payload = {
      SessionId: sessionId
    };

    const response = await axios.post(
      `${API_BASE_URL}/api/chat/deleteWriterAiSession`,
      payload
    );

    console.log("Delete response:", response.data);
    return response.data;
  } catch (error) {
    console.error('Delete API call failed:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw error;
  }
};

export const getWriterAiSessions = async (userId, chatType) => {
  console.log("Getting writer AI sessions:", { userId, chatType });
  try {
    const payload = {
      user_id: userId,
      chat_type: chatType
    };

    const response = await axios.post(
      `${API_BASE_URL}/api/chat/getWriterAiSessions`,
      payload
    );

    console.log("Get sessions response:", response.data);
    return response.data;
  } catch (error) {
    console.error('Get sessions API call failed:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw error;
  }
};

// Keep the existing generateSessionId function
export const generateSessionId = async () => {
  const now = new Date();

  // Get date components
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');

  // Get time components
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

  // Generate random string to ensure uniqueness
  const randomString = Math.random().toString(36).substring(2, 8);

  // Combine all components
  return `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}_${randomString}`;
};
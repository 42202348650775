import React from 'react';
import { Briefcase, MapPin, Building, Calendar, DollarSign, Users, ExternalLink } from 'lucide-react';
import '../styles/linkedinJobDetails.css';
const LinkedinJobDetails = ({ jobDetails }) => {

  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatSalary = (salaryDetails) => {
    if (!salaryDetails?.min_salary || !salaryDetails?.max_salary) return "Not specified";
    
    const amount = salaryDetails.min_salary === salaryDetails.max_salary
      ? `$${salaryDetails.min_salary}`
      : `$${salaryDetails.min_salary} - $${salaryDetails.max_salary}`;
      
    return `${amount}/${salaryDetails.pay_period.toLowerCase()}`;
  };

  return (
    <div className="LinkedinJobDetailsWrapper">
      <div className="LinkedinJobDetailsHeading">
        <h2 className="LinkedinJobDetailsTitleBar">
          <Briefcase className="LinkedinJobDetailsIcon" />
          Job Details
        </h2>
      </div>
      
      <div className="LinkedinJobDetailsContent">
        <div className="LinkedinJobDetailsHeader">
          <div className="LinkedinCompanyLogo">
            {jobDetails.company_name?.[0]?.toUpperCase() || '?'}
          </div>
          
          <div className="LinkedinJobMainInfo">
            <h1 className="LinkedinJobTitle">{jobDetails.job_title}</h1>
            <div className="LinkedinCompanyName">
              <Building size={14} className="LinkedinInlineIcon" />
              {jobDetails.company_name}
            </div>
            {jobDetails.job_location && (
              <div className="LinkedinJobLocation">
                <MapPin size={14} className="LinkedinInlineIcon" />
                {jobDetails.job_location}
              </div>
            )}
          </div>
        </div>

        <div className="LinkedinJobSection">
          <h3 className="LinkedinSectionTitle">Key Information</h3>
          <div className="LinkedinKeyInfoGrid">
            <div className="LinkedinKeyInfoItem">
              <DollarSign size={14} className="LinkedinInlineIcon" />
              <span><strong>Salary:</strong> {formatSalary(jobDetails.salary_details)}</span>
            </div>
            <div className="LinkedinKeyInfoItem">
              <Briefcase size={14} className="LinkedinInlineIcon" />
              <span><strong>Job Type:</strong> {jobDetails.job_type}</span>
            </div>
            <div className="LinkedinKeyInfoItem">
              <Users size={14} className="LinkedinInlineIcon" />
              <span><strong>Experience:</strong> {jobDetails.experience_level}</span>
            </div>
            <div className="LinkedinKeyInfoItem">
              <Calendar size={14} className="LinkedinInlineIcon" />
              <span><strong>Posted:</strong> {formatDate(jobDetails.posted)}</span>
            </div>
          </div>
        </div>

        <div className="LinkedinJobSection">
          <h3 className="LinkedinSectionTitle">Company Information</h3>
          <p className="LinkedinCompanyDescription">{jobDetails.company_description}</p>
        </div>

        <div className="LinkedinJobSection">
          <h3 className="LinkedinSectionTitle">Industries</h3>
          <div className="LinkedinTagsContainer">
            {jobDetails.industries.map((industry, index) => (
              <span key={index} className="LinkedinTag">{industry}</span>
            ))}
          </div>
        </div>

        {jobDetails.specialities && jobDetails.specialities.length > 0 && (
          <div className="LinkedinJobSection">
            <h3 className="LinkedinSectionTitle">Specialties</h3>
            <div className="LinkedinTagsContainer">
              {jobDetails.specialities.map((specialty, index) => (
                <span key={index} className="LinkedinTag">{specialty}</span>
              ))}
            </div>
          </div>
        )}

        <div className="LinkedinJobSection">
          <h3 className="LinkedinSectionTitle">Job Description</h3>
          <div className="LinkedinJobDescription">
            {jobDetails.job_description}
          </div>
        </div>

        {jobDetails.job_url && (
         
            <a 
              href={jobDetails.job_url} 
              target="_blank" 
              rel="noopener noreferrer"
              className="LinkedinApplyButton"
            >
              <ExternalLink size={14} />
              Apply on LinkedIn
            </a>
      
        )}
      </div>
    </div>
  );
};

export default LinkedinJobDetails;
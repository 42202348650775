import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import '../styles/sequenceListTable.css';

const SequenceList = ({ sequenceList, itemsPerPage = 8 , handleSequenceListData, isFromSequenceExe }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);

  const formatDate = (dateString) => {
    return moment(dateString).format('DD MMM hh:mm A');
  };

  const getType = (emailEngine) => {
    return emailEngine === "2" ? "Sales" : "Marketing";
  };

  const processedList = Array.isArray(sequenceList?.response?.lists_listing)
    ? sequenceList.response.lists_listing.map(item => ({
        id: item.list_id,
        name: item.list_name || 'N/A',
        type: getType(item.email_engine),
        steps: item.action_blocks_count || '0',
        date: formatDate(item.created_at),
        originalData: item  // Store the original item data
      }))
    : [];

  const filteredItems = processedList.filter(item =>
    Object.values(item).some(value =>
      value.toString().toLowerCase().includes(filter.toLowerCase())
    )
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleCheckboxChange = (item) => {
    if (selectedRow && selectedRow.id === item.id) {
      setSelectedRow(null);
      console.log("Deselected:", null);
    } else {
      setSelectedRow(item);
      console.log("Selected:", item.originalData);
    }
  };

  const handleAddToSequence = () => {
    if (selectedRow) {
      console.log("Adding to sequence:", selectedRow.originalData);
      // Add your logic here to handle adding the selected sequence
      handleSequenceListData(selectedRow.originalData, isFromSequenceExe)
    }
  };

  if (!Array.isArray(sequenceList?.response?.lists_listing)) {
    return <div>No sequence data available</div>;
  }

  return (
    <div className="sequence-list-container">
      <p>Select a sequence </p>
      <input
        type="text"
        placeholder="Search..."
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        className="filter-input"
      />
      <table className="sequence-table">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Type</th>
            <th>Steps</th>
            <th>Date</th>
            <th>View</th> {/* New View column */}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item) => (
            <tr key={item.id} className={selectedRow && selectedRow.id === item.id ? 'selected-row' : ''}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedRow && selectedRow.id === item.id}
                  onChange={() => handleCheckboxChange(item)}
                />
              </td>
              <td>
                <a
                  href={`https://meet.onepgr.com/sequences/${item.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="sequence-link"
                >
                  {item.name}
                </a>
              </td>
              <td>{item.type}</td>
              <td>{item.steps}</td>
              <td>{item.date}</td>
              <td>
                <button
                  onClick={() => window.open(`https://meet.onepgr.com/sequences/${item.id}`, '_blank')}
                >
                  View
                </button>
              </td> {/* View button */}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="sequencelist-table-footer">
        <div className="pagination-container">
          <div className="pagination-buttons">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className="pagination-button"
            >
              <ChevronLeft size={20} />
            </button>
            <span className="pagination-info">Page {currentPage} of {totalPages}</span>
            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              <ChevronRight size={20} />
            </button>
          </div>
          
          {selectedRow && (
            <button className="add-to-sequence-button" onClick={handleAddToSequence}>
              Next Step
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SequenceList;

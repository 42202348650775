import React from 'react';
import '../styles/webContent.css';

const WebContent = ({ data }) => {
  // console.log('Received data:', data);

  if (!data) {
    console.error('No data provided to WebContent component');
    return <div>No data available</div>;
  }

  const parseResults = (formattedResults) => {
    if (!formattedResults || typeof formattedResults !== 'string') {
      console.error('Invalid formatted results:', formattedResults);
      return [];
    }

    try {
      const entries = formattedResults.split('\n\n');
      return entries.map(entry => {
        const lines = entry.split('\n').map(line => line.trim());
        let result = {
          title: '',
          link: '',
          description: '',
          date: ''
        };

        lines.forEach(line => {
          if (line.startsWith('Link:')) {
            result.link = line.replace('Link:', '').trim();
          } else if (line.startsWith('Date:')) {
            result.date = line.replace('Date:', '').trim();
          } else if (line.startsWith('Published:')) {
            result.date = line.replace('Published:', '').trim();
          } else if (!result.title && !line.startsWith('Link:') && !line.startsWith('Date:') && !line.startsWith('Published:')) {
            result.title = line.replace(/^\d+\.\s*/, '').trim();
          } else if (!result.description && !line.startsWith('Link:') && !line.startsWith('Date:') && !line.startsWith('Published:')) {
            result.description = line.trim();
          }
        });

        return result;
      }).filter(result => result.title || result.link);
    } catch (error) {
      console.error('Error parsing results:', error);
      return [];
    }
  };

  const formattedData = typeof data === 'string' ? data : data.formattedResults;
  const results = parseResults(formattedData);

  if (results.length === 0) {
    return <div>No results found</div>;
  }

  return (
    <div className="web-content-container">
      <h2 className="web-content-title">Web Search Results</h2>
      <div className="search-results-container">
        {results.map((result, index) => (
          <div key={index} className="search-result-item">
            <h3 className="result-title">
              {`${index + 1}. ${result.title || 'Untitled'}`}
            </h3>
            {result.link && (
              <a 
                href={result.link}
                target="_blank"
                rel="noopener noreferrer"
                className="result-link"
              >
                {result.link}
              </a>
            )}
            {result.description && (
              <p className="result-description">{result.description}</p>
            )}
            {result.date && (
              <p className="result-date">Published: {result.date}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WebContent;
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import axios from 'axios';
import { faPlus, faClock, faChevronDown, faCog, faRobot, faSignOutAlt, faChevronRight, faUser, faBaby, faBucket, faBullhorn, faBullseye } from '@fortawesome/free-solid-svg-icons';
import { getRecentChats } from '../api/api';
import '../styles/sideBar.css';
import { UserContext } from './context/userContext';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import { deleteBabuChatSession, updateBabuChatName } from '../api/babuApi';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FaLinkedin, FaBlog, FaSearch, FaBullseye, FaVideo, FaComments, FaEnvelope, FaBullhorn, FaBox, FaChartBar } from 'react-icons/fa';
import { getWriterAiSessions } from '../api/writeraiApi';

const Sidebar = ({ 
    onLogout, 
    onNewChat, 
    userId, 
    recentChats, 
    activeSessionId, 
    onChatSelect,
    onWriterAiChatSelect,  // Remove the default value
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [user, setUser] = useState(null);

    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [linkInput, setLinkInput] = useState('');
    const [isRecentsOpen, setIsRecentsOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sessionType = "4"
    const [showAllChats, setShowAllChats] = useState(false);
    const [isProspectAIOpen, setIsProspectAIOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [editingChatId, setEditingChatId] = useState(null);
    const [editedTitle, setEditedTitle] = useState('');
    const [visibleChats, setVisibleChats] = useState([])
    const [isWriterAIRecentsOpen, setIsWriterAIRecentsOpen] = useState(false);
    const [showWriterAIChatTypes, setShowWriterAIChatTypes] = useState(false);
    const [selectedWriterAIType, setSelectedWriterAIType] = useState(null);
    const [writerAiSessions, setWriterAiSessions] = useState({});
    const [isLoadingWriterAi, setIsLoadingWriterAi] = useState(false);
    const [openContentType, setOpenContentType] = useState(null);
    const [activeSessionDropdown, setActiveSessionDropdown] = useState(null);
    const [editingSessionId, setEditingSessionId] = useState(null);
    const [editedSessionTitle, setEditedSessionTitle] = useState('');
    const [isActionPointOpen, setIsActionPointOpen] = useState(false);

    const contentTypes = [
        { id: 'linkedin', name: 'LinkedIn Post', icon: <FaLinkedin />, description: 'Professional networking updates and thought leadership' },
        { id: 'blog', name: 'Blog Article', icon: <FaBlog />, description: 'In-depth articles and blog posts' },
        { id: 'seo', name: 'SEO Article', icon: <FaSearch />, description: 'Search engine optimized content with keyword targeting' },
        { id: 'landing', name: 'Landing Page', icon: <FaBullseye />, description: 'Conversion-focused landing page copy' },
        { id: 'video', name: 'Video Script', icon: <FaVideo />, description: 'Engaging video content scripts' },
        { id: 'social', name: 'Social Media Post', icon: <FaComments />, description: 'Engaging social media content' },
        { id: 'email', name: 'Email Campaign', icon: <FaEnvelope />, description: 'Email marketing campaigns' },
        { id: 'ad', name: 'Ad Copy', icon: <FaBullhorn />, description: 'Compelling advertising copy' },
        { id: 'product', name: 'Product Description', icon: <FaBox />, description: 'Detailed product descriptions' },
        { id: 'case', name: 'Case Study', icon: <FaChartBar />, description: 'In-depth case studies and whitepapers' },
        { id: 'custom-content', name: 'Custom Content', icon: <FaPencilAlt />, description: 'Custom content' }
    ];
    const ALL_CONTENT_TYPES = [
        'LinkedIn Post',
        'Blog Article',
        'SEO Article',
        'Landing Page',
        'Video Script',
        'Social Media Post',
        'Email Campaign',
        'Ad Copy',
        'Product Description',
        'Case Study',
        'Custom Content'
    ];

    // useEffect(() => {
    //     if (user && user.userId) {
    //       fetchRecentChats();
    //     }
    //   }, [user]);

    useEffect(() => {
        const fetchWriterAiSessions = async () => {
            if (user && user.userId) {
                setIsLoadingWriterAi(true);
                try {
                    const response = await getWriterAiSessions(user.userId, "writerAi");
                    const processedSessions = processWriterAiSessions(response.data);
                    setWriterAiSessions(processedSessions);
                } catch (error) {
                    console.error('Error fetching Writer AI sessions:', error);
                } finally {
                    setIsLoadingWriterAi(false);
                }
            }
        };

        fetchWriterAiSessions();
    }, [user]);



    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        setUser(userData);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };


    const handleMouseEnter = () => {
        setIsSidebarOpen(true);
    };

    const handleMouseLeave = () => {
        setIsSidebarOpen(false);
    };


    const handleRecentChatClick = (sessionId) => {
        console.log("Recent chat clicked. SessionId:", sessionId);
        console.log("Current location:", location.pathname);
        const newPath = `/chat/${sessionId}`;
        console.log("New path:", newPath);
        if (location.pathname !== newPath) {
            console.log("Navigating to:", newPath);
            navigate(newPath, { replace: true });
        } else {
            console.log("Already on the correct path, not navigating.");
        }
    };

    const toggleContentType = (contentType, e) => {
        e.stopPropagation();
        setOpenContentType(openContentType === contentType ? null : contentType);
    };

    const toggleSessionOptions = (e, sessionId) => {
        e.stopPropagation();
        setActiveSessionDropdown(activeSessionDropdown === sessionId ? null : sessionId);
    };

    const handleSessionRename = (sessionId, currentTitle) => {
        setEditingSessionId(sessionId);
        setEditedSessionTitle(currentTitle);
        setActiveSessionDropdown(null);
    };

    const handleSessionDelete = async (sessionId, contentType) => {
        try {
            // Add your delete API call here
            const updatedSessions = { ...writerAiSessions };
            delete updatedSessions[contentType][sessionId];
            setWriterAiSessions(updatedSessions);
        } catch (error) {
            console.error('Error deleting session:', error);
        }
    };

    const saveSessionTitle = async (sessionId, contentType) => {
        // Add your rename API call here
        setEditingSessionId(null);
        setEditedSessionTitle('');
    };


    const toggleRecents = () => {
        setIsRecentsOpen(!isRecentsOpen);
        if (!isRecentsOpen) {
            setShowAllChats(false);
        }
    };
    const toggleShowAllChats = () => setShowAllChats(!showAllChats);
    // let visibleChats = showAllChats ? recentChats : recentChats.slice(0, 5);
    useEffect(() => {
        setVisibleChats(showAllChats ? recentChats : recentChats.slice(0, 5));
    }, [showAllChats, recentChats]);

    const toggleProspectAI = () => setIsProspectAIOpen(!isProspectAIOpen);

    const handleProspectAIClick = (e) => {
        // Check if the click is on the chevron icon
        if (e.target.closest('.accordion-icon')) {
            // If clicking the chevron, just toggle
            setIsProspectAIOpen(!isProspectAIOpen);
        } else {
            // If clicking elsewhere in the header, navigate
            navigate('/chat');
        }
    };

    const toggleAccordion = () => setIsAccordionOpen(!isAccordionOpen);

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleLinkInputChange = (e) => {
        setLinkInput(e.target.value);
    };

    const handleFileUpload = (e) => {
        // Handle file upload logic here
        console.log('File uploaded:', e.target.files);
    };

    function navigateToRoute(route) {
        navigate(route)
    }

    const handleWriterAIClick = () => {
        navigate('/writer-ai');
    };

    const toggleWriterAIRecents = (e) => {
        e.stopPropagation(); // Prevent navigation
        setIsWriterAIRecentsOpen(!isWriterAIRecentsOpen);
    };

    const toggleWriterAIChatTypes = () => {
        setShowWriterAIChatTypes(!showWriterAIChatTypes);
    };

    const toggleChatTypeContent = (typeId, e) => {
        e.stopPropagation();
        setSelectedWriterAIType(selectedWriterAIType === typeId ? null : typeId);
    };

    const processWriterAiSessions = (data) => {
        const sessions = {};

        // Initialize all content types with empty objects
        ALL_CONTENT_TYPES.forEach(type => {
            sessions[type] = {};
        });

        // Group messages by SessionId
        data.forEach(item => {
            const sessionId = item.SessionId;
            const contentType = item.chat_content_type;

            if (!sessions[contentType]) {
                sessions[contentType] = {};
            }

            if (!sessions[contentType][sessionId]) {
                sessions[contentType][sessionId] = [];
            }

            // Parse the History string to JSON
            const historyData = JSON.parse(item.History);
            sessions[contentType][sessionId].push({
                ...item,
                historyData
            });
        });

        // Sort messages within each session by _id
        Object.keys(sessions).forEach(contentType => {
            Object.keys(sessions[contentType]).forEach(sessionId => {
                sessions[contentType][sessionId].sort((a, b) => a._id.localeCompare(b._id));
            });
        });

        return sessions;
    };

    const getContentTypeId = (contentTypeName) => {
        const contentType = contentTypes.find(type => type.name === contentTypeName);
        return contentType ? contentType.id : contentTypeName.toLowerCase().replace(/\s+/g, '-');
    };

    const handleWriterAiSessionClick = (sessionId, contentType, e) => {
        e.preventDefault();
        e.stopPropagation();

        const contentTypeId = getContentTypeId(contentType);
        const path = `/writer-ai/${contentTypeId}/${sessionId}`;
        
        console.log('Navigating to:', path);
        navigate(path);
    };

    const extractMainIntent = (content) => {
        if (!content) return 'Untitled Chat';

        const words = content.split(' ');
        const shortIntent = words.slice(0, 5).join(' ');
        return words.length > 5 ? `${shortIntent}...` : shortIntent;
    };

    const formatDate = (date) => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = monthNames[d.getMonth()];
        let hours = d.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const minutes = d.getMinutes().toString().padStart(2, '0');

        return {
            datePart: `${day} ${month}`,
            timePart: `${hours}:${minutes} ${ampm}`
        };
    };

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const getAvatarContent = () => {
        if (!user) return '';
        if (user.avatar) return <img src={user.avatar} alt="User avatar" className="user-avatar-img" />;
        return user.name.charAt(0).toUpperCase();
    };

    const handleAccountSettings = () => {
        navigate('/profile/settings');
    }
    const handleProfileClick = () => {
        navigate('/profile');
    }
    const handleApplicationTesting = () => {
        navigate('/application-testing');
    }
    const handleWriterAiTesting = () => {
        navigate('/writer-ai');
    }

    const toggleDropdownOptions = (event, chatId) => {
        event.stopPropagation();  // Prevent triggering onClick of the chat item
        setActiveDropdown(activeDropdown === chatId ? null : chatId);
    };

    const handleRename = (chatId, currentTitle) => {
        setEditingChatId(chatId);
        setEditedTitle(currentTitle || 'Untitled Chat');
        setActiveDropdown(null);
    };

    const handleTitleChange = (e) => {
        setEditedTitle(e.target.value);
    };

    const saveTitle = async (chatId) => {
        const updatedChats = visibleChats.map((chat) =>
            chat._id === chatId ? { ...chat, chat_name: editedTitle } : chat
        );
        setVisibleChats(updatedChats);
        await updateBabuChatName({ sessionId: chatId, chat_name: editedTitle });
        setEditingChatId(null);
        setEditedTitle('');
    };

    const handleDelete = async (chatId) => {
        // Add Confirmation Modal to delete
        const updatedChats = visibleChats.filter(chat => chat._id !== chatId);
        setVisibleChats(updatedChats);
        await deleteBabuChatSession({ sessionId: chatId });
        console.log('Deleted chat with ID:', chatId);
    };

    const handleKeyDown = (e, chatId) => {
        if (e.key === 'Enter') {
            saveTitle(chatId);
        }
    };

    const toggleActionPoint = () => {
        setIsActionPointOpen(!isActionPointOpen);
    };

    const handleActionPointClick = () => {
        navigate('/action-point');
    };

    return (
        <>
            <div
                className="sidebar-trigger"
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    width: '10px',
                    height: '100%',
                    zIndex: 100
                }}
                onMouseEnter={handleMouseEnter}
            ></div>
            <div
                className={`sidebar ${isSidebarOpen ? 'open' : ''}`}
                onMouseLeave={handleMouseLeave}
            >
                <button className="new-chat-btn" onClick={onNewChat}>
                    <FontAwesomeIcon icon={faPlus} /> Start new chat
                </button>
                <div className="sidebar-apps-content">

                    {/* Prospect AI Section */}
                    <div className="sidebar-section">
                        <h3 onClick={handleProspectAIClick}  className="accordion-header">
                            <FontAwesomeIcon icon={faRobot} /> Prospect Finder
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className={`accordion-icon ${isProspectAIOpen ? 'open' : ''}`}
                            />
                        </h3>
                        <div className={`accordion-content-main ${isProspectAIOpen ? 'open' : ''}`}>
                            {/* Moved Recents section here */}
                            <div className="sidebar-section">
                                <h3 onClick={toggleRecents} className="accordion-header">
                                    <FontAwesomeIcon icon={faClock} /> Recents
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className={`accordion-icon ${isRecentsOpen ? 'open' : ''}`}
                                    />
                                </h3>
                                <div className={`accordion-content ${isRecentsOpen ? 'open' : ''} ${showAllChats ? 'show-all' : ''}`}>
                                    {recentChats.length > 0 ? (
                                        <>
                                            <ul className="chat-list">
                                                {visibleChats.map((chat) => (
                                                    <li
                                                        key={chat._id}
                                                        className={`chat-item ${chat._id === activeSessionId ? 'active' : ''}`}
                                                        onClick={() => onChatSelect(chat._id)}
                                                    >
                                                        <div className="chat-content">
                                                            <span className="chat-intent">
                                                                {editingChatId === chat._id ? (
                                                                    <input
                                                                        type="text"
                                                                        value={editedTitle}
                                                                        onChange={handleTitleChange}
                                                                        onBlur={() => saveTitle(chat._id)}
                                                                        onKeyDown={(e) => handleKeyDown(e, chat._id)}
                                                                        autoFocus
                                                                    />
                                                                ) : (
                                                                    chat.chat_name ? chat.chat_name : chat.messages && chat.messages[0]?.data.content || 'Untitled Chat'
                                                                )}
                                                            </span>
                                                            <div className="chat-timestamp">
                                                                <div>{formatDate(chat.messages && chat.messages[0]?.data.time).datePart}</div>
                                                                <div>{formatDate(chat.messages && chat.messages[0]?.data.time).timePart}</div>
                                                            </div>
                                                            <div className="edit-chat-options">
                                                                <span style={{ cursor: 'pointer' }} onClick={(e) => toggleDropdownOptions(e, chat._id)}>⋮</span>
                                                                {activeDropdown === chat._id && (
                                                                    <div className="dropdown-menu-chat-options">
                                                                        <button onClick={() => handleRename(chat._id, chat.chat_name ? chat.chat_name : chat.messages && chat.messages[0]?.data.content || 'Untitled Chat')}>
                                                                            <FaPencilAlt style={{ marginRight: '15px' }} /> Rename
                                                                        </button>
                                                                        <button onClick={() => handleDelete(chat._id)} style={{ color: '#f93a37' }}>
                                                                            <FaTrash style={{ marginRight: '15px' }} /> Delete
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                            {recentChats.length > 5 && isRecentsOpen && (
                                                <button className="view-all-btn" onClick={toggleShowAllChats}>
                                                    {showAllChats ? 'View less ←' : 'View all →'}
                                                </button>
                                            )}
                                        </>
                                    ) : (
                                        <p className="no-chats-message">No recent chats to show</p>
                                    )}
                                </div>
                            </div>

                            {/* Moved Custom Content section here */}
                            <div className="custom-context-section">
                                <h4 onClick={toggleAccordion}>
                                    Custom Content
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className={`accordion-icon ${isAccordionOpen ? 'open' : ''}`}
                                    />
                                </h4>
                                <div className={`accordion-content ${isAccordionOpen ? 'open' : ''}`}>
                                    <div className="accordion-inner">
                                        <div className="content-selector">
                                            <label htmlFor="content-dropdown">Add content:</label>
                                            <select id="content-dropdown">
                                                <option value="">Select Content Type</option>
                                                <option value="option1">Option 1</option>
                                                <option value="option2">Option 2</option>
                                                <option value="option3">Option 3</option>
                                                <option value="option4">Option 4</option>
                                            </select>
                                        </div>
                                        <div className="radio-options">
                                            <label>
                                                <input
                                                    className='radio-input'
                                                    type="radio"
                                                    value="onepgr"
                                                    checked={selectedOption === 'onepgr'}
                                                    onChange={handleOptionChange}
                                                />
                                                OnePgr
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="upload"
                                                    checked={selectedOption === 'upload'}
                                                    onChange={handleOptionChange}
                                                />
                                                Upload
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="addLink"
                                                    checked={selectedOption === 'addLink'}
                                                    onChange={handleOptionChange}
                                                />
                                                URL
                                            </label>
                                        </div>
                                        {selectedOption === 'addLink' && (
                                            <div className="link-input">
                                                <input
                                                    type="text"
                                                    value={linkInput}
                                                    onChange={handleLinkInputChange}
                                                    placeholder="Enter link"
                                                />
                                                <button className='add-btn-url'>Add</button>
                                            </div>
                                        )}
                                        {selectedOption === 'upload' && (
                                            <div className="file-upload-area">
                                                <input
                                                    type="file"
                                                    id="file-upload"
                                                    onChange={handleFileUpload}
                                                    style={{ display: 'none' }}
                                                />
                                                <label htmlFor="file-upload" className="file-upload-label">
                                                    Drop your files here
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="custom-context-section">
                        <h4 onClick={() => { navigateToRoute('/ai-campaign') }}>
                            <FontAwesomeIcon icon={faBullhorn} className="ai-campaign-icon" />
                            AI Campaign
                        </h4>
                    </div>

                    {/* <div className="custom-context-section">
                        <h4 onClick={handleApplicationTesting}>
                            Application-testing
                        </h4>
                    </div> */}
                    <div className="custom-context-section writer-ai-section">
                        <div className="accordion-header writer-ai-main-header">
                            <div className="writer-ai-title" onClick={handleWriterAIClick}>
                                <FontAwesomeIcon icon={faEdit} className="writer-ai-icon" />
                                <h4>Writer AI</h4>
                            </div>
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className={`accordion-icon ${isWriterAIRecentsOpen ? 'open' : ''}`}
                                onClick={toggleWriterAIRecents}
                            />
                        </div>
                        <div className={`accordion-content-main ${isWriterAIRecentsOpen ? 'open' : ''}`}>
                            {/* Recents Section */}
                            <div className="sidebar-section writer-ai-section">
                                <div className="accordion-header recents-header" onClick={toggleWriterAIChatTypes}>
                                    <div className="recents-title">
                                        <FontAwesomeIcon icon={faClock} />
                                        <span>Recents</span>
                                    </div>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className={`accordion-icon ${showWriterAIChatTypes ? 'open' : ''}`}
                                    />
                                </div>

                                <div className={`accordion-content ${showWriterAIChatTypes ? 'open' : ''}`}>
                                    {isLoadingWriterAi ? (
                                        <div className="loading-message">Loading sessions...</div>
                                    ) : (
                                        ALL_CONTENT_TYPES.map((contentType) => (
                                            <div key={contentType} className="writer-ai-content-type">
                                                <div
                                                    className="content-type-header"
                                                    onClick={(e) => toggleContentType(contentType, e)}
                                                >
                                                    <div className="content-type-info">
                                                        <span>{contentType}</span>
                                                    </div>
                                                    <FontAwesomeIcon
                                                        icon={faChevronRight}
                                                        className={`accordion-icon ${openContentType === contentType ? 'open' : ''}`}
                                                    />
                                                </div>
                                                <div className={`content-type-sessions ${openContentType === contentType ? 'open' : ''}`}>
                                                    {Object.entries(writerAiSessions[contentType] || {}).length > 0 ? (
                                                        Object.entries(writerAiSessions[contentType]).map(([sessionId, messages]) => {
                                                            const firstMessage = messages.find(msg =>
                                                                JSON.parse(msg.History).type === 'human'
                                                            );
                                                            const title = firstMessage ?
                                                                JSON.parse(firstMessage.History).data.content :
                                                                'Untitled Chat';

                                                            const date = formatDate(new Date(sessionId.split('_')[0]));

                                                            return (
                                                               <div
        key={sessionId}
        className="writer-ai-session"
        onClick={(e) => handleWriterAiSessionClick(sessionId, contentType, e)}
    >
                                                                    <div className="session-content">
                                                                        <div className="session-left">
                                                                            {editingSessionId === sessionId ? (
                                                                                <input
                                                                                    type="text"
                                                                                    value={editedSessionTitle}
                                                                                    onChange={(e) => setEditedSessionTitle(e.target.value)}
                                                                                    onBlur={() => saveSessionTitle(sessionId, contentType)}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === 'Enter') {
                                                                                            saveSessionTitle(sessionId, contentType);
                                                                                        }
                                                                                    }}
                                                                                    onClick={(e) => e.stopPropagation()}
                                                                                    autoFocus
                                                                                    className="session-title-input"
                                                                                />
                                                                            ) : (
                                                                                <span className="session-title">{title}</span>
                                                                            )}
                                                                        </div>
                                                                        <div className="session-right">
                                                                            <div className="session-time">
                                                                                <div className="time-date">{date.datePart}</div>
                                                                                <div className="time-hour">{date.timePart}</div>
                                                                            </div>
                                                                            <div className="session-options">
                                                                                <span
                                                                                    className="options-trigger"
                                                                                    onClick={(e) => toggleSessionOptions(e, sessionId)}
                                                                                >
                                                                                    ⋮
                                                                                </span>
                                                                                {activeSessionDropdown === sessionId && (
                                                                                    <div className="session-dropdown-menu">
                                                                                        <button onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                            handleSessionRename(sessionId, title);
                                                                                        }}>
                                                                                            <FaPencilAlt className="button-icon" /> Rename
                                                                                        </button>
                                                                                        <button
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                handleSessionDelete(sessionId, contentType);
                                                                                            }}
                                                                                            className="delete-button"
                                                                                        >
                                                                                            <FaTrash className="button-icon" /> Delete
                                                                                        </button>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                        <div className="no-sessions-message">No recent chats</div>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Action Point Section */}
                    <div className="custom-context-section action-point-section">
                        <div className="accordion-header action-point-main-header">
                            <div className="action-point-title" onClick={handleActionPointClick}>
                            <FontAwesomeIcon icon={faBullseye} className="writer-ai-icon" />
                                <h4>Action Point</h4>
                            </div>
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className={`accordion-icon ${isActionPointOpen ? 'open' : ''}`}
                                onClick={toggleActionPoint}
                            />
                        </div>
                        <div className={`accordion-content-main ${isActionPointOpen ? 'open' : ''}`}>
                            {/* Add your Action Point content here */}
                            <div className="sidebar-section action-point-section">
                                <div className="accordion-header recents-header">
                                    <div className="recents-title">
                                        <FontAwesomeIcon icon={faClock} />
                                        <span>Recents</span>
                                    </div>
                                </div>
                                <div className="accordion-content">
                                    {/* Add your recent Action Point items here */}
                                    <p>No recent action points to show</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sidebar-footer">
                    {user && (
                        <div className="user-info" onClick={toggleDropdown}>
                            <div className="user-avatar">
                                {getAvatarContent()}
                            </div>
                            <div className="user-email">{user.name}</div>
                            <FontAwesomeIcon icon={faChevronDown} className={`dropdown-icon ${isDropdownOpen ? 'open' : ''}`} />
                        </div>
                    )}
                    {isDropdownOpen && (
                        <div className="profile-dropdown-menu">
                              <button onClick={handleProfileClick }><FontAwesomeIcon icon={faUser} />Profile</button>
                            <button onClick={handleAccountSettings}><FontAwesomeIcon icon={faCog} />Account Settings</button>
                            <button onClick={onLogout}><FontAwesomeIcon icon={faSignOutAlt} /> Logout</button>
                        </div>
                    )}
                </div>

            </div>
        </>
    );
};

export default Sidebar;